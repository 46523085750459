<!-- 分类管理 -->

<template>
  <el-container class="Wrapper">
    <el-container class="container">
      <el-main class="main">
        <div class="main_items">
          <div class="classification">
            <div class="table-as" style="margin-left: 1%; margin-top:-1vh; overflow: auto;" height="70vh" :delay="100">
              <router-view />
              <el-collapse accordion @change="handleChange">
                <el-collapse-item height="100" v-for="(item, index) in bleDatas" :key="index" v-bind:title="item.name"
                  @click="getcate(item.id)">
                  <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="tableDatas" border
                    style="width: 100%">
                    <el-table-column label="序号" width="80">
                      <template v-slot="{ $index }">
                        {{ $index + 1 }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="id" label="分类ID" width="200">
                    </el-table-column>
                    <el-table-column prop="name" label="分类名称" width="200">
                    </el-table-column>
                    <el-table-column label="修改" width="200">
                      <template #default="scope" v-if="prop === 'id'"></template>
                      <template #default="scope">
                        <el-button type="primary" @click="primary(scope.row.id, scope.row.storeId)"
                          :icon="Edit">编辑</el-button>
                        <el-button type="danger" @click="danger(scope.row.id)" :icon="Delete">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>
<script setup>
// import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref } from 'vue';
import axios from 'axios';
import { toRaw } from 'vue';
import { useRouter } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit, Delete, Plus } from '@element-plus/icons-vue'


const tableDatas = ref([]);
const bleDatas = ref([]);
const datas = ref(null);
const tabas = ref(null);
const loading = ref(true);
const router = useRouter();
const inputData = ref('')

if (localStorage.getItem('permissionsStatus') === '0') {
  instance.get('/api/stores')
    .then(response => {
      tabas.value = response.data;
      console.log(tabas.value);
      bleDatas.value = toRaw(tabas.value);
      console.log(bleDatas.value);
    }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
} else {
  instance.get(`/api/stores/${localStorage.getItem('sysStoreId')}`)
    .then((response => {
      tabas.value = response.data.data;
      bleDatas.value = [toRaw(tabas.value)]
      console.log(bleDatas.value);
    }))
    .catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
}
const getcate = (id) => {
  loading.value = true;
  instance.get(`/api/stores/cate/${id}`)
    .then(response => {
      datas.value = response.data.data;
      tableDatas.value = toRaw(datas.value);
      console.log(tableDatas.value);
      console.log(toRaw(datas.value));
      console.log(tableDatas.value);
      loading.value = false;
    }).catch(error => {
      console.log(error);
      ElMessage({
        showClose: true,
        message: '请登录',
        type: 'error',
      })
      router.push('/')
    })
};
const danger = (id) => {
  //根据id删除数据库中该数据
  ElMessageBox.confirm(
    '确定要删除该分类嘛?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      instance.delete(`api/categories/${id}`)
        .then(response => {
          console.log(response);
          // loading.value = true
          if (response.data.code === 0) {
            ElMessage({
              type: 'error',
              message: `${response.data.msg}`,
            })
          } else {
            ElMessage({
              type: 'success',
              message: `${response.data.data}`,
            })
            // loading.value = false
            window.location.reload();
          }
        })

    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消删除',
      })
    })
}
const primary = (id, storeId) => {
  console.log(id);
  ElMessageBox.prompt('你确定要修改该分类嘛', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
  })
    .then(({ value }) => {
      if (value.trim() === '') {
        this.$message({
          type: 'error',
          message: '输入不能为空'
        })
      } else {
        var edit = {
          "id": id,
          "name": value,
          "storeId": storeId
        }
        instance.put(`/api/categories`, edit)
          .then(response => {
            ElMessage({
              type: 'success',
              message: `修改成功，新分类名为:${value}`,
            })
            window.location.reload();
          })
          .catch(error => {
            console.log(error);
            ElMessage({
              type: 'error',
              message: `修改失败`,
            })
          })

      }
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消修改',
      })
    })
}
</script>

<style>
.container {
  position: fixed;
  top: 0;
  left: 15vw;
}



.main {
  display: inline-block;
  position: fixed;
  left: 15vw;
  width: 85vw;
  height: 90vh;
}

.main_items {
  position: fixed;
  left: 11vw;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgb(189, 187, 187);
  top: 10vh
}


.classification .el-table tr.el-table__row {
  height: 10vh !important;
  /* background-color: aqua; */
}

.classification .el-collapse-item__row {
  height: 10vh !important;
}

.classification .el-table__header {
  height: 70px !important;
}

.classification .el-collapse-item__header {
  height: 10vh !important;
}

.classification .el-button__row {
  height: 100px !important;
  /* background-color: aqua; */
}

.classification .el-collapse .but {
  position: fixed;
  margin-left: 40vw;
  margin-top: 20px;
}

.classification .el-collapse .but {
  display: inline-flex;
  position: fixed;
  margin-left: 40vw;
  margin-top: 20px;
  position: sticky;
}

.classification {
  font-size: 12px !important;
}

.classification .table-as {
  position: fixed;
  top: 8vh;
  width: 89vw;
  /* background-color: #fff; */
  left: 10vw;
  /* position: fixed; */
  /* top: -6vh; */
  /* width: 80vw; */
  height: 100vh;
  margin-top: 15%;
  box-shadow: 0px 0px 10px rgba(17, 16, 16, 0.5);
}
</style>