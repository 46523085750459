<!-- 查询所有用户 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="alluser">
                <div class="alluserhead">
                    <el-input v-model="userinput" placeholder="请输入用户昵称" @keyup.enter="alluserQuery"
                        class="alluserSearch"></el-input>
                    <el-button type="primary" @click="alluserQuery" class="alluserSearchBtn">查询</el-button>
                </div>
                <div class="alluserbody">
                    <el-table :data="Allusers" height="75vh" border style="width: 100%" v-loading="loading"
                        element-loading-text="加载中，请稍后...">
                        <el-table-column prop="wxUserId" label="用户ID" width="180" />
                        <el-table-column prop="nickName" label="用户名" width="180" />
                        <el-table-column prop="avatarUrl" label="头像" width="180">
                            <template #default="scope">
                                <div style="width: 50px;height: 50px;display: flex;align-items: center;"
                                    v-if="scope.row.avatarUrl !== null && scope.row.avatarUrl !== ''">
                                    <el-image fit="fill" lazy :src="`${scope.row.avatarUrl}`">
                                    </el-image>
                                </div>
                                <div style="width: 60px;height: 60px;display: flex;align-items: center;" v-else>
                                    <span>暂无头像</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="gender" label="性别">

                            <template #default="scope">
                                <span v-if="scope.row.gender == 0 || scope.row.gender == null">未知</span>
                                <span v-else-if="scope.row.gender == 1">男</span>
                                <span v-else-if="scope.row.gender == 2">女</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="member" label="是否为会员">

                            <template #default="scope">
                                <span v-if="scope.row.member == 1">是</span>
                                <span v-else-if="scope.row.member == 0">否</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="alluserfoot">
                    <div class="demo-pagination-blocks">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4" @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const Allusers = ref([])
const Allusersa = ref(null)
const userinput = ref('')
const loading = ref(true)
const tatol = ref(0);
const router = useRouter();

// if (localStorage.getItem('permissionsStatus') === '0') {
    instance.get('/api/wxuser/getAllUser')
        .then(response => {
            tatol.value = response.data.data.length
            loading.value = false
        })
        .catch(error => {
            console.log(error);
            console.error(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${1}&pageSize=${10}`)
        .then(response => {
            Allusersa.value = response.data
            Allusers.value = toRaw(Allusersa.value)
            console.log(Allusers.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            console.error(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
// } else {
//     loading.value = false
//     ElMessage({
//         type: 'error',
//         message: '您权限不够',
//     })
// }

const alluserQuery = () => {
    loading.value = true
    console.log(userinput.value);
    if (userinput.value === '') {
        instance.get('/api/wxuser/getAllUser')
            .then(response => {
                Allusersa.value = response.data.data
                Allusers.value = toRaw(Allusersa.value)
                console.log(Allusers.value);
                loading.value = false
            })
            .catch(error => {
                console.log(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                router.push('./')
            })
    } else {
        instance.get('/api/wxuser/getAllUser')
            .then(response => {
                const matchedUsers = [];
                Allusersa.value = response.data.data;
                for (let i = 0; i < Allusersa.value.length; i++) {
                    if (Allusersa.value[i].nickName === userinput.value) {
                        matchedUsers.push(Allusersa.value[i]);
                    }
                }
                if (matchedUsers.length > 0) {
                    Allusers.value = matchedUsers;
                } else {
                    console.log('6666');
                }
                loading.value = false;
            })
            .catch(error => {
                console.log(error);
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                router.push('./')
            });
    }
}
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            Allusersa.value = response.data
            Allusers.value = toRaw(Allusersa.value)
            console.log(Allusers.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
</script>

<style>


.container {
    position: fixed;
    top: 0;
    left: 15vw;
}




.alluser {
    margin-left: -4vw;
    margin-top: 7vh;
    width: 100vw;
    height: 100vh;
}

.alluserbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.alluserfoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.alluserhead {
    margin-top: 3vh;
}

.alluserSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.alluserSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.alluserfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}
</style>