<!-- 积分管理 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="alllnve">
                <div class="alllnvehead">
                    <el-input v-model="userinput" placeholder="请输入用户ID" @keyup.enter="alllnveQuery"
                        class="alllnveSearch"></el-input>
                    <el-button type="primary" @click="alllnveQuery" class="alluserSearchBtn">查询</el-button>
                    <el-button type="primary" @click="alllnveAdd" class="alluserSearchBtn">新增规则</el-button>
                </div>
                <div class="alllnvebody">
                    <el-table :data="Alllnve" height="75vh" border style="width: 100vw" v-loading="loading"
                        element-loading-text="加载中，请稍后...">
                        <el-table-column prop="name" label="规则名称" width="150" />
                        <el-table-column prop="type" label="规则类型" width="150" />
                        <el-table-column prop="pointCondition" label="积分条件" width="150">
                        </el-table-column>
                        <el-table-column prop="points" label="积分数额" width="80">
                        </el-table-column>
                        <el-table-column prop="threshold" label="阈值" width="80">
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="180">
                        </el-table-column>
                        <el-table-column prop="updateTime" label="更新时间" width="180">
                        </el-table-column>
                        <el-table-column prop="" label="操作">
                            <template #default="scope">
                                <el-button type="primary" @click="alllnveEdit(scope.row.settingId)"
                                    class="alllnveEditBtn">编辑</el-button>
                                <el-button type="danger" @click="alllnveDelete(scope.row.settingId)"
                                    class="alllnveDeleteBtn">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="alllnvefoot">
                    <div class="demo-pagination-blocks">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                            @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div>
                <div class="PopUps">
                    <div v-if="showModals" class="commentmodeae">
                        <div class="modals-content">
                            <span class="close" @click="toggleModals">&times;</span>
                            <span class="edadmin">编辑规则</span>
                            <el-form :model="form" label-width="auto" style="max-width: 30vw; margin-top: 5vh">
                                <el-form-item label="规则名称">
                                    <el-input v-model="name" placeholder="请输入规则名称" />
                                </el-form-item>
                                <el-form-item label="阈值">
                                    <el-input-number v-model="threshold" :min="1" />
                                </el-form-item>
                                <el-form-item label="规则类型">
                                    <el-select v-model="type" placeholder="请选择规则类型">
                                        <el-option label="签到积分" value="签到积分" />
                                        <el-option label="任务积分" value="任务积分" />
                                        <el-option label="消费积分" value="消费积分" />
                                        <el-option label="邀请奖励" value="邀请奖励" />
                                        <el-option label="生日礼包" value="生日礼包" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="规则描述">
                                    <el-input v-model="pointCondition" type="textarea" placeholder="请输入规则描述" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit">提交</el-button>
                                    <el-button @click="toggleModals">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
                <div class="PopUps">
                    <div v-if="showModalsa" class="commentmodeae">
                        <div class="modals-content">
                            <span class="close" @click="toggleModalsa">&times;</span>
                            <span class="edadmin">新增规则</span>
                            <el-form :model="form" label-width="auto" style="max-width: 30vw; margin-top: 5vh">
                                <el-form-item label="规则名称">
                                    <el-input v-model="namea" placeholder="请输入规则名称" />
                                </el-form-item>
                                <el-form-item label="积分">
                                    <el-input-number v-model="points" :min="1" />
                                </el-form-item>
                                <el-form-item label="阈值">
                                    <el-input-number v-model="thresholda" :min="1" />
                                </el-form-item>
                                <el-form-item label="规则类型">
                                    <el-select v-model="typea" placeholder="请选择规则类型">
                                        <el-option label="签到积分" value="签到积分" />
                                        <el-option label="任务积分" value="任务积分" />
                                        <el-option label="消费积分" value="消费积分" />
                                        <el-option label="邀请奖励" value="邀请奖励" />
                                        <el-option label="生日礼包" value="生日礼包" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="规则描述">
                                    <el-input v-model="pointConditiona" type="textarea" placeholder="请输入规则描述" />
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmita">提交</el-button>
                                    <el-button @click="toggleModalsa">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const Alllnve = ref([])
const Alllnvea = ref(null)
const userinput = ref('')
const loading = ref(true)
const name = ref('')
const type = ref('')
const createTime = ref('')
const threshold = ref('')
const pointCondition = ref('')
const namea = ref('')
const typea = ref('')
const thresholda = ref('')
const pointConditiona = ref('')
const showModals = ref(false)
const showModalsa = ref(false)
const tatol = ref(0);
const router = useRouter();
const points = ref('')
const settingIda = ref('')
const page = reactive({ pageNum: 1, pageSize: 10 });
instance.get(`/api/PointsSetting/selectRule?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        console.log(response)
        Alllnvea.value = response.data.data.pointsSettingVOList
        Alllnve.value = toRaw(Alllnvea.value)
        tatol.value = response.data.data.totalCount
        loading.value = false
    })
    .catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })


const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/PointsSetting/selectRule?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            console.log(response)
            Alllnvea.value = response.data.data.pointsSettingVOList
            Alllnve.value = toRaw(Alllnvea.value)
            tatol.value = response.data.data.totalCount
            loading.value = false
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
const alllnveDelete = (settingId) => {
    console.log(settingId);
    ElMessageBox.confirm(
        '此操作将永久删除该积分规则, 是否继续?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/PointsSetting/deleteIntegralRule?settingId=${settingId}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '删除成功!',
                    })
                    siftse();
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        message: '请重新登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消删除',
            })
        })
}
const toggleModals = () => {
    showModals.value = !showModals.value;
    type.value = ''
    name.value = ''
    pointCondition.value = ''
    threshold.value = ''
    settingIda.value = ''
}
const toggleModalsa = () => {
    showModalsa.value = !showModalsa.value;
    typea.value = ''
    namea.value = ''
    pointConditiona.value = ''
    thresholda.value = ''
    points.value = ''
}

const alllnveEdit = (settingId) => {
    showModals.value = true
    console.log(settingId);
    instance.get(`/api/PointsSetting/selectByidRule?settingId=${settingId}`)
        .then(response => {
            console.log(response);
            type.value = response.data.data.type
            name.value = response.data.data.name
            pointCondition.value = response.data.data.pointCondition
            threshold.value = response.data.data.threshold
            createTime.value = response.data.data.createTime
            settingIda.value = response.data.data.settingId
        }).catch(error => {
            console.log(error);
        })
}
const alllnveAdd = () => {
    showModalsa.value = true;
}
const onSubmit = () => {
    console.log(type.value, name.value, pointCondition.value, threshold.value, createTime.value,settingIda.value);
    const today = ref(new Date());
    const years = today.value.getFullYear();
    const months = (today.value.getMonth() + 1).toString().padStart(2, '0');
    const days = today.value.getDate().toString().padStart(2, '0');
    console.log(`${years}/${months}/${days}`);
    const hours = today.value.getHours().toString().padStart(2, '0');
    const minutes = today.value.getMinutes().toString().padStart(2, '0');
    const seconds = today.value.getSeconds().toString().padStart(2, '0');
    console.log(`${hours}:${minutes}:${seconds}`);
    const updateTime = `${years}-${months}-${days} ${hours}:${minutes}:${seconds}`
    console.log(updateTime);
    const data = {
        settingId: settingIda.value,
        type: type.value,
        name: name.value,
        pointCondition: pointCondition.value,
        threshold: threshold.value,
        updateTime: updateTime,
    }
    console.log(data);
    instance.put(`/api/PointsSetting/modificationOfRule`, data)
        .then(response => {
            console.log(response.data);
            ElMessage({
                message: '修改成功',
                type: 'success',
            })
            toggleModals()
            siftse();
        }).catch(error => {
            console.log(error);
        })
}
const onSubmita = () => {
    const data = {
        type: typea.value,
        name: namea.value,
        pointCondition: pointConditiona.value,
        threshold: thresholda.value,
        points: points.value
    }
    console.log(data);
    instance.post(`/api/PointsSetting/newIntegrationRule`, data)
        .then(response => {
            console.log(response.data);
            ElMessage({
                message: '新增成功',
                type: 'success',
            })
            toggleModalsa()
            siftse();
        }).catch(error => {
            console.log(error);
        })
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}


.alllnve {
    margin-left: -4vw;
    margin-top: 7vh;
    width: 100vw;
    height: 100vh;
}

.alllnvebody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.alllnvefoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.alllnvehead {
    margin-top: 3vh;
}

.alllnveSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.alllnveSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.alllnvefoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}

.alllnvebody .el-table--border .el-table__inner-wrapper::after {
    z-index: 2;
}
</style>