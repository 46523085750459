<!-- 编辑视频 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="addvideo">
                <p>
                    <span class="name">视频简介:</span>
                    <span class="input"> <el-input v-model="textarea" maxlength="100" placeholder="请输入视频的介绍"
                            show-word-limit type="textarea" /></span>
                </p>
                <p>
                    <span class="name"><i style="color: red;">*</i>视频分类：</span>
                    <span class="inputs">
                    <el-select v-model="valuese" placeholder="请选择视频的分类" @change="video">
                        <el-option v-for="item in managementese" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select></span>
                </p>
                <p>
                    <span class="name">商品详情视频</span>
                    <span><el-upload class="upload-demo" action="" :before-upload="beforeAvatarUploadsd" multiple
                            :limit="3">
                            <video v-if="videoUrl !== ''" :src="videoUrl" controls autoplay></video>
                            <el-button v-else type="primary">点击上传</el-button>
                            <div v-if="uploading">正在上传...</div>
                            <div v-if="uploaded">上传完成！</div>
                        </el-upload></span>
                </p>
                <p>

                </p>
                <p>
                    <span> <el-button @click="uploads" type="primary">上传</el-button></span>
                    <span class="bus"><el-button @click="concel" type="primary">返回</el-button></span>
                </p>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import { ref, toRaw } from 'vue'

const router = useRouter()
const route = useRoute()
const namec = ref('')
const textarea = ref('');
const editvideos = ref([]);
const editvideoe = ref('');

const videoUrl = ref('');
const uploading = ref(false);
const uploaded = ref(false);
instance.get(`/api/common/downloadVideo?name=${route.query.wxVideo}`)
    .then(response => {
        videoUrl.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    })
console.log(route.query.wxVideo);
console.log(route.query.details);
textarea.value = route.query.details;
const valuese = ref('')
//判断该视频的分类
if (route.query.titleId === '1') {
    valuese.value = '开心一笑'
    console.log(valuese.value);
}
if (route.query.titleId === '2') {
    valuese.value = '老年人健康'
}
//查找该视频的信息
instance.get('/api/wxVideo/' + route.query.id)
    .then(response => {
        editvideoe.value = response.data.data;
        editvideos.value = toRaw(editvideoe.value);
        console.log(editvideos.value);
        console.log(videoUrl.value);
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
//上传视频
const beforeAvatarUploadsd = (file) => {
    console.log(file);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)
        .then(response => {
            console.log('上传成功');
            console.log(file);
            console.log(response);
            namec.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(namec.value);
        }).catch(response => {
            console.log(response);
            console.log('上传失败')
            ElMessage({
                showClose: true,
                message: '图片上传失败，请联系管理员重启分配资源',
                type: 'error',
            })
        })

    console.log(formData);
    // const isJPG = file.type === '.mp4';
    const isLt2M = file.size / 1024 / 1024 < 200;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 10000);
    clearTimeout(timer.value);

    // if (!isJPG) {
    //     alert('上传头像图片只能是 JPG 格式!');
    // }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    console.log("1111");
    return isJPG && isLt2M;
};
const concel = () => {
    router.push('./operate')
}

const managementese = [
    {
        value: '1',
        label: '开心一笑',
    },
    {
        value: '2',
        label: '老年人健康',
    }
]

//保存信息
const uploads = () => {
    console.log(textarea.value);
    console.log(namec.value);
    ElMessageBox.confirm(
        '确定上传该视频?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            //判断视频介绍
            if (textarea.value === '') {
                if (route.query.details === '') {
                    ElMessage({
                        type: 'error',
                        message: '视频介绍为空',
                    })
                    return;
                } else {
                    textarea.value = route.query.details
                }

            }
            //判断视频是否为空
            if (namec.value === '') {
                if (route.query.details === '') {
                    ElMessage({
                        type: 'error',
                        message: '视频为空',
                    })
                    return;
                } else {
                    namec.value = route.query.wxVideo
                }
            }
            console.log(namec.value);
            //存储api需要信息
            const ade = {
                "id": route.query.id,
                "titleId": 1,
                "wxVideo": namec.value,
                "details": textarea.value
            }
            console.log(ade);
            //编辑视频信息
            instance.put(`api/wxVideo`, ade)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '视频上传成功',
                    })
                    // router.push('operate')
                }).catch(() => {
                    ElMessage({
                        type: 'error',
                        message: '视频上传失败',
                    })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消上传',
            })
        })
}




</script>

<style>
.addvideo {
    /* display: flex; */
    width: 100vw;
    height: 100vh;
    margin-top: 10vh;
}

.addvideo span {
    font-size: 1rem;
}

.addvideo .bus {
    /* padding-left: 1vw; */
    margin-left: 1vw;
}

.addvideo .el-upload video {
    width: 20vw;
    height: 10vw;
}
.addvideo .el-select{
width: 20vw;
}
</style>