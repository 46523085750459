<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <!-- <a href="https://baidu.com" target="_blank"> -->
                        欢迎进入管理系统
                    <!-- </a> -->
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";
 
    
    const router = useRouter();
    const route = useRoute();
   
</script>

<style>
    .leftnavbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 15vw;
        height: 100vh;
        background-color: #545c64;
    }

    .container {
        position: fixed;
        top: 0;
        left: 15vw;
    }

    .container_top {
        width: 85vw;
        height: 10vh;
        background-color: #545c64;
    }

    .main {
        position: fixed;
        top: 10vh;
        left: 15vw;
        width: 85vw;
        height: 90vh;
    }

    .main_item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 50px;
    }
</style>