<!-- 添加门店 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <el-card class="cards">
                        <el-row :gutter="100">
                            <el-col :span="7">
                                新增门店
                            </el-col>
                        </el-row>
                        <el-row :gutter="100">
                            <el-col :span="7">
                                <!-- ref绑定到上面的属性,用于校验验证表单是否正确输入,model绑定的是整个表单数据,rules绑定的是最外层的总体的那个规则对象 -->
                                <el-from ref="baseForm" :model="loginForm" :rules="loginrules">
                                    <!--label为显示的内容,prop为规则对象内的一个属性,v-model与表单数据双向绑定-->
                                    <el-form-item label="门店名称" prop="storename">
                                        <el-input v-model="name" type="text" :disabled="name.length > 8" />
                                    </el-form-item>
                                    <el-form-item label="门店地址" prop="storename">
                                        <el-input v-model="storaddress" />
                                    </el-form-item> 
                                    <el-form-item label="门店经度" prop="storename">
                                        <el-input v-model="longitude" />
                                    </el-form-item>
                                    <el-form-item label="门店纬度" prop="storename">
                                        <el-input v-model="latitude" />
                                    </el-form-item>
                                </el-from>
                                <p>
                                    上传一张图片用来作为首页图片：
                                </p>
                                <el-upload class="avatar-uploader" action="" :http-request="upload_1"
                                    :show-file-list="false" :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                    <el-icon v-else class="avatar-uploader-icon">
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                                <p>
                                    上传一张图片用来作为门店头像：
                                </p>
                                <el-upload class="avatar-uploader" action="" :http-request="upload_2"
                                    :show-file-list="false" :on-success="handleAvatarSuccess_1"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl_1" :src="imageUrl_1" class="avatar">
                                    <el-icon v-else class="avatar-uploader-icon">
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                                <p>
                                    上传一张图片用来作为门店背景图片：
                                </p>
                                <el-upload class="avatar-uploader" action="" :http-request="upload_3"
                                    :show-file-list="false" :on-success="handleAvatarSuccess_2"
                                    :before-upload="beforeAvatarUpload">
                                    <img v-if="imageUrl_2" :src="imageUrl_2" class="avatar">
                                    <el-icon v-else class="avatar-uploader-icon">
                                        <Plus />
                                    </el-icon>
                                </el-upload>
                                <el-button type="primary" @click="submit">提交</el-button>
                                <el-button type="danger" @click="goBack">取消</el-button>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { watch } from 'vue';
import instance from '../network/storester.js'

const router = useRouter();

const name = ref('');
const storaddress = ref('');
const longitude = ref('');
const latitude = ref('');
console.log(name);
console.log(storaddress);
watch(name, (newVal) => {
    if (newVal.length > 9) {
        name.value = '';
        ElMessage({
            message: '门店名称不能大于9个字，请简写',
            type: 'warning',
        })
    }
});
const loginrules = ref({
    username: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
    ],
    storename: [
        { required: true, message: "信息不能为空", trigger: "blur" },
        { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
    ],
})
const baseForm = ref(null);
const submitForm = () => {
    //对表单元素进行校验
    baseForm.value.validate((valid) => {
        if (!valid) {
            //校验失败
            console.log("请调整数据后再请求");
            return false;
        }
        // 校验成功
        console.log("123123", 123123);
    });
};

//获取到图片的url地址
//用来储存门店首页图片
const imageUrl = ref('');
//图片在本地地址
const imgurl1 = ref('');
const upload_1 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl1.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            ElMessage({
        showClose: true,
        message: '图片上传失败，请联系管理员重启分配资源',
        type: 'error',
    })
        })
}
//用来储存门店头像图片
const imageUrl_1 = ref('');
//图片在本地地址
const imgurl2 = ref('');
const upload_2 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl2.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_1.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            ElMessage({
        showClose: true,
        message: '图片上传失败，请联系管理员重启分配资源',
        type: 'error',
    })
        })
}
//用来储存门店背景图片
const imageUrl_2 = ref('');
//图片在本地地址
const imgurl3 = ref('');
const upload_3 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl3.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_2.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            ElMessage({
        showClose: true,
        message: '图片上传失败，请联系管理员重启分配资源',
        type: 'error',
    })
        })
}
const beforeAvatarUpload = (file) => {
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 1000);
    clearTimeout(timer.value);

    if (!isJPG) {
        alert('上传头像图片只能是 JPG 格式!');
    }
    if (!isLt2M) {
        alert('上传头像图片大小不能超过 2MB!');
    }
    return isJPG && isLt2M;
};

//提交并且将数据写入数据库
const submit = () => {
    ElMessageBox.confirm(
        '是否确认添加该门店',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            // console.log(loginForm.value.storename);
            if (name.value == '') {
                ElMessage({
                    message: '门店名字不能为空',
                    type: 'error',
                })
            }
            if (imgurl1.value == '') {
                ElMessage({
                    message: '门店首页图片不能为空',
                    type: 'error',
                })
            }
            if (imgurl2.value == '') {
                ElMessage({
                    message: '门店背景图片不能为空',
                    type: 'error',
                })
            }
            if (imgurl3.value == '') {
                ElMessage({
                    message: '门店头像图片不能为空',
                    type: 'error',
                })
            }
            const url = "E:/uploadImg";
            const stores = {
                "name": name.value,
                "indexImgUrl": imgurl1.value,
                "storeBgimgUrl": imgurl2.value,
                "storeTximgUrl": imgurl3.value,
                "address": storaddress.value,
                "latitude": latitude.value,
                "longitude": longitude.value

                // "name": "惠普",
                // "indexImgUrl": "images/imges/07.jpg",
                // "storeBgimgUrl": "images/imges/07.jpg",
                // "storeTximgUrl": "images/imges/07.jpg",
                // "address": "广东省 广州市珠江新城"
            };
            console.log(stores);
            // 用axios将数据传到后端
            // http://localhost:8081/api/stores
            instance.post('/api/stores', JSON.stringify(stores), { headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    console.log(response.data);
                    console.log('添加成功');
                    ElMessage({
                        message: '门店添加成功',
                        type: 'success',
                    })
                    router.push('/storemanagement');
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消添加',
            })
        })
};
const goBack = () => {
    router.push('/storemanagement');
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.cards {
    width: 90%;
    display: flex;
    height: 80vh;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
    margin-top: -20vh;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>