<!-- 查询所有用户 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="alluser">
                <div class="alluserhead">
                    <!-- <el-input v-model="userinput" placeholder="请输入用户ID" @keyup.enter="alluserQuery"
                        class="alluserSearch"></el-input> -->
                    <el-button type="primary" @click="alluserQuery" class="alluserSearchBtn">添加配置</el-button>
                </div>
                <div class="alluserbody">
                    <el-table :data="Allusers" height="75vh" border style="width: 100%" v-loading="loading"
                        element-loading-text="加载中，请稍后...">
                        <el-table-column prop="name" label="地区" width="180" />
                        <el-table-column prop="description" label="描述" width="180" />
                        <el-table-column prop="createTime" label="创建时间" width="180">
                        </el-table-column>
                        <el-table-column prop="updateTime" label="修改时间" width="180">
                        </el-table-column>
                        <el-table-column prop="" label="操作">
                            <template #default="scope">
                                <el-button type="primary" @click="handleEdit(scope.row.cityId)">编辑</el-button>
                                <el-button type="danger" @click="handleDelete(scope.row.cityId)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <el-dialog v-model="dialogVisible" title="门店提示" width="500" draggable :before-close="handleClose">
                    <el-form :model="form" label-width="10vw">
                        <el-form-item label="市区名">
                            <el-input class="inputbox" v-model="Urban" style="width: 20vw;" />
                        </el-form-item>
                        <!-- <el-form-item label="所属省或地区">
                            <el-input class="inputbox" v-model="province" style="width: 20vw;" />
                        </el-form-item> -->
                        <el-form-item label="描述">
                            <el-input v-model="tiele" style="width: 20vw" :autosize="{ minRows: 2, maxRows: 4 }"
                                type="textarea" placeholder="描述" />
                        </el-form-item>
                    </el-form>
                    <template #footer>
                        <div class="dialog-footer">
                            <el-button @click="toggleModalss">取消</el-button>
                            <el-button type="primary" @click="onSubmitss">
                                确认
                            </el-button>
                        </div>
                    </template>
                </el-dialog>
                <!-- <div class="alluserfoot">
                    <div class="demo-pagination-blocks">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4" @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div> -->
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const Allusers = ref([])
const Allusersa = ref(null)
const userinput = ref('')
const loading = ref(true)
// const tatol = ref(0);
const router = useRouter();
const dialogVisible = ref(false)
const province = ref('')
const Urban = ref('')
const tiele = ref('')
const fals = ref(false)
const ida = ref('')
const toggleModalss = () => {
    dialogVisible.value = !dialogVisible.value
    province.value = ''
    Urban.value = ''
    tiele.value = ''
}
// if (localStorage.getItem('permissionsStatus') === '0') {
instance.get('/api/remoteCity/getRemoteCityList')
    .then(response => {
        console.log(response.data.data)
        Allusersa.value = response.data.data
        Allusers.value = toRaw(Allusersa.value)
        loading.value = false
    })
    .catch(error => {
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const fia = () => {
    instance.get('/api/remoteCity/getRemoteCityList')
        .then(response => {
            console.log(response.data.data)
            Allusersa.value = response.data.data
            Allusers.value = toRaw(Allusersa.value)
            loading.value = false
        })
        .catch(error => {
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
const handleClose = () => {
    toggleModalss()
}
// } else {
//     loading.value = false
//     ElMessage({
//         type: 'error',
//         message: '您权限不够',
//     })
// }
const alluserQuery = () => {
    dialogVisible.value = !dialogVisible.value

}
const handleDelete = (cityId) => {
    ElMessageBox.confirm(
        '确认要删除该条配置嘛',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/remoteCity/deleteRemoteCityById?cityId=${cityId}`)
                .then(() => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    fia()
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消操作',
                    })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消操作',
            })


        })
}
// const alluserQuery = () => {
//     loading.value = true
//     console.log(userinput.value);
//     if (userinput.value === '') {
//         instance.get('/api/wxuser/getAllUser')
//             .then(response => {
//                 Allusersa.value = response.data.data
//                 Allusers.value = toRaw(Allusersa.value)
//                 console.log(Allusers.value);
//                 loading.value = false
//             })
//             .catch(error => {
//                 console.log(error);
//                 ElMessage({
//                     message: '请重新登录',
//                     type: 'error',
//                 })
//                 router.push('./')
//             })
//     } else {
//         instance.get('/api/wxuser/getAllUser')
//             .then(response => {
//                 const matchedUsers = [];
//                 Allusersa.value = response.data.data;
//                 for (let i = 0; i < Allusersa.value.length; i++) {
//                     if (Allusersa.value[i].wxUserId === userinput.value) {
//                         matchedUsers.push(Allusersa.value[i]);
//                     }
//                 }
//                 if (matchedUsers.length > 0) {
//                     Allusers.value = matchedUsers;
//                 } else {
//                     console.log('6666');
//                 }
//                 loading.value = false;
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.error(error);
//                 ElMessage({
//                     message: '请重新登录',
//                     type: 'error',
//                 })
//                 router.push('./')
//             });
//     }
// }
const handleEdit = (cityId) => {
    instance.get(`/api/remoteCity/getRemoteCityById?cityId=${cityId}`)
        .then(res => {
            if (res.data.state === 20000) {
                Urban.value = res.data.data.name
                province.value = res.data.data.province
                tiele.value = res.data.data.description
                ida.value = res.data.data.cityId
                dialogVisible.value = true
                fals.value = true
            }
            // toggleModalss()
            console.log(res.data)
        }).catch(err => {
            console.log(err)
        })
}
const onSubmitss = () => {
    console.log(Urban.value)
    console.log(province.value)
    if (Urban.value == '') {
        ElMessage({
            type: 'error',
            message: '请输入市区名称',
        })
    }
    if (province.value == '') {
        ElMessage({
            type: 'error',
            message: '请输入市区所属省区',
        })
    }
if (fals.value  == false) {
    const data = {
        province: province.value,
        name: Urban.value,
        description: tiele.value
    }
    instance.post(`/api/remoteCity/insertRemoteCity`, data)
        .then(res => {
            console.log(res)
            ElMessage({
                type: 'success',
                message: '添加成功',
            })
            toggleModalss()
            fia()
        }).catch(err => {
            console.log(err)
        })
}else{
    const data = {
        cityId: ida.value,
        province: province.value,
        name: Urban.value,
        description: tiele.value
    }
    console.log(data)
    instance.put(`/api/remoteCity/updateRemoteCity`, data)
        .then(res => {
            console.log(res)
            ElMessage({
                type: 'success',
                message: '修改成功',
            })
            toggleModalss()
            fia()
        }).catch(err => {
            console.log(err)
        })
}
   
}
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/wxuser/paginationWxUser?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            Allusersa.value = response.data
            Allusers.value = toRaw(Allusersa.value)
            console.log(Allusers.value);
            loading.value = false
        }).catch(error => {
            console.log(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}




.alluser {
    margin-left: -4vw;
    margin-top: 7vh;
    width: 100vw;
    height: 100vh;
}

.alluserbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.alluserfoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.alluserhead {
    margin-top: 3vh;
}

.alluserSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.alluserSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.alluserfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}
</style>