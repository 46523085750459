<!-- 佣金提现 -->

<template>
    <el-container class="Wrapper">
        <el-container class="containerse">
            <div class="Withdrawal">
                <el-table :data="wxpayouts" style="width: 100%" height="75vh" max-height="250" border>
                    <el-table-column fixed prop="realName" label="提现申请人" width="200">
                    </el-table-column>
                    <el-table-column prop="createTime" label="提现时间" width="100">
                    </el-table-column>
                    <el-table-column prop="money" label="金额" width="50">
                    </el-table-column>
                    <el-table-column prop="" label="状态" width="50">
                        <template #default="{ row }">
                            <span v-if="row.payoutStatus === 1">申请提现</span>
                            <span v-else-if="row.payoutStatus === 2">成功提现</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="提现方式" width="100">
                        <template #default="{ row }">
                            <span v-if="row.payoutType === 1">支付宝提现</span>
                            <span v-else-if="row.payoutType === 2">银行卡提现</span>
                            <span v-else-if="row.payoutType === 3">微信支付</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="提现账户" width="200">
                        <template #default="scope">
                            <span v-if="scope.row.payoutType === 3">
                                <el-image style="width: 80px; height: 80px"
                                    :src="image[scope.$index]"
                                    :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="image[scope.$index]"
                                    :initial-index="4" fit="cover" class="img" />
                            </span>
                            <span v-else>
                                {{ row.payoutAccount }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bank" label="银行卡开户行" width="100" />
                    <el-table-column label="操作" width="200">
                        <template #default="{ row }">
                            <el-button type="primary" @click="finish(row.id)">确认</el-button>
                            <el-button type="primary" @click="Hitback(row.id, row.wxUserId, row.money)">打回</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import { useRouter } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, toRaw } from "vue";

const srcList = ref([])
const srcList1 = ref([])
const wxpayouts = ref([]);
const wxpayout = ref(null);
const router = useRouter();
const images = ref([])
const image = ref([])

if (localStorage.getItem('permissionsStatus') !== '0') {
    ElMessage({
        type: 'error',
        message: '您权限不够',
    })
} else {
    instance.get('/api/wxPayouts')
        .then(response => {
            wxpayout.value = response.data.data;
            wxpayouts.value = toRaw(wxpayout.value);
            console.log(wxpayouts.value);
            for (let i = 0; i < wxpayout.value.length; i++) {
                srcList.value.push(wxpayout.value[i].payoutAccount)
            }
            // for (let j = 0; j < srcList.value.length; j++) {
            //     srcList1.value.push(`https://199shq.com/api/common/download?name=${srcList.value[j]}`)
            // }
            // console.log(srcList1.value);
            for (let i = 0; i < srcList.value.length; i++) {
                images.value.splice(i, images.value.length)
                images.value.push(srcList.value[i].payoutAccount)
            }
            fetchData();
            console.log(images.value);
            console.log(image.value);
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                type: 'error',
                message: '请重新登录',
            })
            router.push('/')
        })
}
const finish = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '确定已给该用户提现吗?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/wxPayouts/${id}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '提现成功',
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消提现',
            })
        })
}
const moneys = ref('')
const Hitback = (id, wxUserId, money) => {
    console.log(wxUserId);
    ElMessageBox.confirm(
        '确定已给该用户提现打回吗?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.get(`/api/distribution/selectDistributionAmountByWxUserId/${wxUserId}`)
                .then(response => {
                    console.log(response.data.data);
                    moneys.value = money + response.data.data.distributionAmount
                    console.log(moneys.value);
                    instance.put(`api/distribution?money=${moneys.value}&wxUserld=${wxUserId}`)
                        .then(response => {
                            console.log(response);
                        })
                        .catch(error => {
                            console.log(error);
                        })
                })
            instance.put(`/api/wxPayouts/status/${id}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '打回成功',
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })


        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消提现',
            })
        })
}
async function fetchData() {

    console.log(image.value);
    image.value.splice(0, image.value.length);
    // image.value = []
    for (let j = 0; j < images.value.length; j++) {
        console.log('111');
        try {
            const response = await instance.get(`/api/common/download?name=${images.value[j]}`);
            console.log('333');
            image.value.splice(j, image.value.length);
            image.value.push(response.request.responseURL);
        } catch (error) {
            console.log(error);
        }
    }
    console.log(image.value);
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

/* .main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
} */
.Withdrawal {
    width: 85vw;
    display: flex;
    margin-left: -3vw;
}


.Withdrawal .imge {
    width: 5vw;
    height: 5vw;
}

.Withdrawal .image-slot {
    font-size: 30px;
}

.Withdrawal .image-slot .el-icon {
    font-size: 30px;
}

.Withdrawal .el-image {
    width: 100%;
    height: 200px;
}

.Withdrawal .demo-image__error .image-slot {
    font-size: 30px;
}

.Withdrawal .demo-image__error .image-slot .el-icon {
    font-size: 30px;
}

.Withdrawal .demo-image__error .el-image {
    width: 50vw;
    height: 50vh;
    z-index: 99999;
}

.Withdrawal .el-table .el-table__cell {
    position: static;
}

.Withdrawal .el-image-viewer__canvas img {
    max-height: 50% !important;
    max-width: 50% !important;
}
</style>