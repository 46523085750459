<!-- 公告列表 -->

<template>
  <el-container class="Wrapper">
    <el-container class="container">
      <el-main class="main">
        <div class="main_item">
          <div class="announcements">
            <el-table :data="announcements" height="80vh" style="width: 100%" v-loading="loading"
              element-loading-text="加载中，请稍后...">
              <el-table-column prop="title" label="标题" width="80" />
              <el-table-column label="内容" width="300">
                <template #default="scope">
                  <div v-if="scope.row.mediaType !== 2" v-html="scope.row.content"></div>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="180" />
              <el-table-column prop="status" label="发布状态" width="180">
                <template #default="scope">
                  <el-tag type="danger" v-if="scope.row.status === 1">未发布</el-tag>
                  <el-tag type="success" v-if="scope.row.status === 2">已发布</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="mediaUrl" label="发布视频或图片" width="200">
                <template #default="scope">
                  <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                    <el-image fit="fill" lazy v-if="scope.row.mediaType === 1"
                      :src="`https://199shq.com/api/common/download?name=${scope.row.mediaUrl}`">
                    </el-image>
                    <video style="width: 200px ;height: 80px;" v-if="scope.row.mediaType === 2"
                      :src="`http://199cstorage.com/${scope.row.mediaUrl}`" controls></video>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import { ref, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'

const router = useRouter()
const images = ref([])
const image = ref([])
const announcements = ref([])
const announcementst = ref(null)
const loading = ref(true)
instance.get('/api/announcement/getAnnouncementList')
  .then(response => {
    announcementst.value = response.data.data
    console.log(announcementst.value)
    announcements.value = toRaw(announcementst.value)
    for (let i = 0; i < announcements.value.length; i++) {
      images.value.splice(i, images.value.length)
      images.value.push(announcements.value[i].mediaUrl)
    }
    fetchData();
    console.log(images.value);
    console.log(image.value);
    console.log(announcements.value);
    loading.value = false
  }).catch(error => {
    console.log(error);
    ElMessage({
      showClose: true,
      message: '请登录',
      type: 'error',
    })
    router.push('/')
  })
async function fetchData() {

  console.log(image.value);
  image.value.splice(0, image.value.length);
  // image.value = []
  for (let j = 0; j < images.value.length; j++) {
    console.log('111');
    try {
      const response = await instance.get(`/api/common/download?name=${images.value[j]}`);
      console.log('333');
      image.value.splice(j, image.value.length);
      image.value.push(response.request.responseURL);
    } catch (error) {
      console.log(error);
    }
  }
  console.log(image.value);
}
</script>

<style>
.leftnavbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15vw;
  height: 100vh;
  background-color: #545c64;
}

.container {
  position: fixed;
  top: 0;
  left: 15vw;
}

.container_top {
  width: 85vw;
  height: 10vh;
  background-color: #545c64;
}

.main {
  position: fixed;
  top: 10vh;
  left: 15vw;
  width: 85vw;
  height: 90vh;
}
</style>