<!-- 管理商品 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="shang">
                <div class="comm">
                    <div class="fen">
                        <el-input v-model="input" placeholder="请输入商品名称" @keyup.enter="query"
                            class="OrderNumber"></el-input>
                        <el-switch class="switchs" v-model="value2" size="large" active-text="下架" inactive-text="全部"
                            @change="offshelf" />
                        <!-- <el-select v-model="value" v-if="manage !== '0'" clearable placeholder="请选择商品的分类"
                            @change="addsd">
                            <el-option v-for="item in classifys" :key="item.value" :label="item.label"
                                :value="item.name" />
                        </el-select> -->
                        <el-button type="primary" @click="addsa" class="addproduct">添加商品</el-button>
                        <!-- <el-button type="primary" v-if="manage === '0'" @click="synchronization"
                            class="addproducts">同步</el-button> -->
                    </div>
                    <div class="alluserbody">
                        <el-table v-loading="loading" element-loading-text="加载中，请稍后..."
                            style="width: 100%; margin-left: 10%;" height="85%" :data="management" class="orders">
                            <el-table-column label="序号" width="40vw">
                                <template v-slot="{ $index }">
                                    {{ $index + 1 }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="名称" width="200vw">
                            </el-table-column>
                            <el-table-column prop="price" label="价格" width="50vw">
                            </el-table-column>
                            <el-table-column prop="stock" label="库存" width="70vw">
                                <template #default="scope">
                                    <el-tag v-if="scope.row.stock <= 1 && scope.row.specStock <= 1" type="error" style="color: red;">需补货</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="categoriesName" label="所属分类" width="100vw">
                            </el-table-column>
                            <el-table-column v-if="manage ==='0'" prop="storeName" label="所属门店" width="100vw">
                            </el-table-column>
                            <el-table-column prop="coverUrl" label="图片" width="80vw">
                                <template #default="scope">
                                    <div style="width: 50px;height: 50px;display: flex; align-items: center;">
                                        <el-image fit="fill" lazy :src="`https://199shq.com/api/common/download?name=${scope.row.coverUrl}`" error>
                                            <el-icon><icon-picture /></el-icon>
                                        </el-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="修改" width="250vw">
                                <template #default="scope" v-if="prop === 'id'"></template>
                                <template #default="scope" v-if="prop === 'name'"></template>
                                <template #default="scope" v-if="prop === 'price'"></template>
                                <template #default="scope" v-if="prop === 'coverUrl'"></template>
                                <template #default="scope">
                                    <el-button type="primary" v-if="scope.row.productStatus === 1"
                                        @click="goToUpdaStore(scope.row.id, scope.row.name, scope.row.price, scope.row.coverUrl, scope.row.storeName, scope.row.categoriesName, scope.row.storeId, scope.row.commission, scope.row.stock, scope.row.productStatus)">编辑</el-button>
                                    <el-button type="danger" @click="open(scope.row.id)">删除商品</el-button>
                                    <el-button type="primary" v-if="scope.row.productStatus === 0"
                                        @click="shelves(scope.row.id)">上架</el-button>
                                    <el-button type="primary" v-else @click="takedown(scope.row.id)">下架</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="" label="详情" width="450vw" flexd="right">
                                <template #default="scope">
                                    <el-button type="primary" @click="detail(scope.row.id)">详情</el-button>
                                    <el-button type="danger" @click="DeleteDetails(scope.row.id)">删除详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="alluserfoots">
                        <div class="demo-pagination-blocks">
                            <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                                :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </el-container>
    </el-container>
</template>
<script setup>
import { ref, reactive } from 'vue';
import { toRaw } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Picture as IconPicture } from '@element-plus/icons-vue'

const images = ref([])
const image = ref([])
const tatol = ref(0);
const management = ref([]);
const managem = ref(null);
const loading = ref(true)
const router = useRouter();
const input = ref('');
const manage = localStorage.getItem('permissionsStatus');
const value2 = ref(false)
const page = reactive({ pageNum: 1, pageSize: 10 });
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    // if (value2.value === true) {
    //     offshelf()
    //     return
    // }
    loading.value = true;
    siftsea();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    // if (value2.value === true) {
    //     offshelf()
    //     return
    // }
    loading.value = true;
    siftsea();
};
//设置分类

const siftsea = () => {
    if (localStorage.getItem('permissionsStatus') === '0') {
        value2.value = false
        input.value = ''
        instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {

                managem.value = response.data.data.allProductInfo;
                management.value = toRaw(managem.value);
                tatol.value = response.data.data.totalCount
                console.log(management.value);
                
                loading.value = false;
                console.log(images.value);
                console.log(image.value);
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                // router.push('./')
            })

    } else {
        instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                loading.value = true;
                value2.value = false
                input.value = ''
                managem.value = response.data.data;
                management.value = toRaw(managem.value);
                instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=1&pageSize=${10000}`)
                    .then(response => {
                        tatol.value = response.data.data.length
                    })
                loading.value = false;
                console.log(management.value);
                console.log(management.value);
            }).catch(error => {
                console.log(error);
                ElMessage({
                    showClose: true,
                    message: '请登录',
                    type: 'error',
                })
                router.push('./')
            })
    }
}
if (localStorage.getItem('permissionsStatus') === '0') {
    instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            console.log(response);
            managem.value = response.data.data.allProductInfo;
            management.value = toRaw(managem.value);
            tatol.value = response.data.data.totalCount
            console.log(management.value);
            loading.value = false;
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
} else {
    instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
        .then(response => {
            console.log(response);
            managem.value = response.data.data;
            console.log(managem.value);
            instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=1&pageSize=${10000}`)
                .then(response => {
                    tatol.value = response.data.data.length
                })
            management.value = toRaw(managem.value);
            console.log(management.value);
            loading.value = false;
            console.log(management.value);
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            router.push('./')
        })
}

const offshelf = () => {
    console.log(value2.value);
    loading.value = true;
    if (value2.value === true) {
        if (localStorage.getItem('permissionsStatus') === '0') {
            instance.get(`/api/tproduct/takeDown?pageNum=${1}&pageSize=${10000}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/storeTakeDown?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${1}&pageSize=${10000}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    } else {
        if (localStorage.getItem('permissionsStatus') === '0') {
            instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data.allProductInfo;
                    console.log(managem.value);
                    tatol.value = response.data.data.totalCount
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value)
                    instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${1}&pageSize=${10000}`)
                        .then(response => {
                            tatol.value = response.data.data.length
                        })
                    loading.value = false;
                    console.log(management.value);
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    }
}
async function fetchData() {

    console.log(image.value);
    image.value.splice(0, image.value.length);
    // image.value = []
    for (let j = 0; j < images.value.length; j++) {
        console.log('111');
        try {
            const response = await instance.get(`/api/common/download?name=${images.value[j]}`);
            console.log('333');
            image.value.splice(j, image.value.length);
            image.value.push(response.request.responseURL);
        } catch (error) {
            console.log(error);
        }
    }
    console.log(image.value);
}


const query = () => {
    value2.value = false
    console.log(value2.value);
    if (localStorage.getItem('permissionsStatus') === '0') {
        loading.value = true;
        console.log(input.value);
        console.log(input.value);
        if (input.value === '') {
            instance.get(`/api/tproduct/product/all?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data.allProductInfo;
                    console.log(managem.value);
                    tatol.value = response.data.data.totalCount
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    loading.value = false;
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/search?name=${input.value}`)
                .then(responses => {
                    console.log(responses.data);
                    management.value.splice(0, management.value.length);
                    console.log(management.value);
                    for (var i = 0; i < responses.data.length; i++) {
                        management.value.push(responses.data[i]);
                    }
                    for (let i = 0; i < management.value.length; i++) {
                        images.value.splice(i, images.value.length)
                        images.value.push(management.value[i].coverUrl)
                    }
                    fetchData();
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '没有该商品',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    } else {
        if (input.value === '') {
            instance.get(`/api/tproduct/selectTproductByStoreId?storeId=${localStorage.getItem('sysStoreId')}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
                .then(response => {
                    managem.value = response.data.data;
                    console.log(managem.value);
                    management.value = toRaw(managem.value);
                    console.log(management.value);
                    console.log(management.value);
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    // router.push('./')
                })
        } else {
            instance.get(`/api/tproduct/store/search/${localStorage.getItem('sysStoreId')}?name=${input.value}`)
                .then(responses => {
                    console.log(responses.data.data);
                    managem.value = responses.data.data;
                    management.value = toRaw(managem.value)
                    console.log(management.value);
                    loading.value = false;
                })
                .catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }
    }

};

const goToUpdaStore = (id, name, price, images, storeName, categoriesName, storeId, commission, stock, productStatus) => {
    console.log(id);
    console.log(name);
    console.log(price);
    console.log(images);
    console.log(storeName);
    console.log(storeId);
    if (localStorage.getItem('permissionsStatus') === '0') {
        router.push({
            path: '/editproduct',
            // name: 'home',
            query: {
                msg: id,
                name: name,
                price: price,
                images: images,
                storeName: storeName,
                categoriesName: categoriesName,
                storeId: storeId,
                commission: commission,
                stock: stock,
                productStatus: productStatus,
            }
        })
    }else{
        router.push({
            path: '/editproduct',
            // name: 'home',
            query: {
                msg: id,
                name: name,
                price: price,
                images: images,
                storeName: storeName,
                categoriesName: categoriesName,
                storeId: localStorage.getItem('sysStoreId'),
                commission: commission,
                stock: stock,
                productStatus: productStatus,
            }
        })
    }

}
const detail = (id) => {
    console.log(id);
    router.push({
        path: '/productdetails',
        query: {
            msg: id
        }
    })
};
const takedown = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要下架该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/tproduct/status/${id}`)
                .then(response => {
                    loading.value = true
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: `下架成功`,
                    })
                    siftsea();
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消下架',
            })
        })
}
const shelves = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要上架该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/tproduct/status/${id}`)
                .then(response => {
                    loading.value = true
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: `上架成功`,
                    })
                    siftsea();
                })
            console.log('上架成功');
            siftsea();

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消上架',
            })
        })

}

const open = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要删除该商品吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/tproduct/forcedDeletion/${id}`)
                .then(response => {
                    console.log(1);
                    console.log(response);
                    loading.value = true
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    siftsea();
                }).catch((error) => {
                    console.log(error);
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除',
                    })
                })
        })
}
const DeleteDetails = (id) => {
    console.log(id);

    ElMessageBox.confirm(
        '您确定要删除该商品详情吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/productVideo/${id}`)
            instance.delete(`/api/details/${id}`)
                .then(response => {
                    console.log(response);
                    loading.value = true
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    siftsea();
                })
                .catch((error) => {
                    console.log(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const addsa = () => {
    router.push('../AllProducts')
}



</script>
<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 11vw;
    width: 85vw;
    height: 90vh;
}

.OrderNumber {
    /* top: -46.5vh !important; */
    left: -3vw !important;
}

.orders {
    position: fixed;
    margin-top: 15vh;
    left: 8vw;
}

.fen .el-select {
    position: fixed;
    top: 14vh;
    left: 28vw;
    width: 14vw;
    font-size: 12px;
}

.comm .el-table {
    position: fixed;
    top: 6%;
    left: 1%;
}

.comm .el-table tr.el-table__row {
    height: 65px !important;
    font-size: 12px !important;
}

.comm .el-button {
    height: 24px !important;
    font-size: 10px !important;
}

.shang {
    /* width: 85%; */
    font-size: 12px !important;
}

.el-table {
    width: 100%;
}

.el-table__header-wrapper table,
.el-table__body-wrapper table {
    width: 100% !important;
}

.el-table__body,
.el-table__footer,
.el-table__header {
    table-layout: auto;
}

.addproduct {
    position: fixed;
    top: 14vh;
    right: 20vw;
}

.addproducts {
    position: fixed;
    top: 14vh;
    right: 15vw;
}

.fen {
    position: fixed;
    top: 13vh;
    z-index: 1;
}

/* .fen .el-switch {
    top: -46.5vh !important;
    left: -5vw !important;
} */

.fen .el-input {
    width: 15vw;
}

.fen .switchs {
    left: 30vw;
}

.alluserfoots .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 94vh;
    height: 10vh;
    left: 30vw;
    /* background-color: red; */
}
</style>