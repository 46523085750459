<!-- 查询用户评论 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="comment">
                <div class="commenthead">
                    <el-input v-model="userinput" placeholder="请输入商品id" @keyup.enter="commentQuery"
                        class="commentSearch"></el-input>
                    <el-select class="commentSearch" v-model="region" placeholder="请选择评论">
                        <el-option label="好评" value="1" />
                        <el-option label="中评" value="2" />
                        <el-option label="差评" value="3" />
                        <el-option label="所有" value="4" />
                    </el-select>
                    <el-button type="primary" @click="commentQuery" class="commentSearchBtn">查询</el-button>
                    <el-button type="primary" @click="commentAll" class="commentSearchBtn">新增评论</el-button>
                </div>
                <div class="commentbody">
                    <el-table :data="comment" height="75vh" border style="width: 100%" v-loading="loading"
                        element-loading-text="加载中，请稍后...">
                        <el-table-column fixed prop="productId" label="商品ID" width="200px" />
                        <el-table-column   prop="userId" label="用户ID" width="200px"  />
                        <el-table-column prop="orderId" label="订单ID" width="200px" />
                        <el-table-column prop="imageUrl" label="头像" width="200px">
                            <template #default="scope">
                                <div style="width: 50px;height: 50px;display: flex;align-items: center;"
                                    v-if="scope.row.imageUrl !== null && scope.row.imageUrl !== '' && scope.row.imageUrl !== undefined">
                                    <el-image fit="fill" lazy :src="`${scope.row.imageUrl}`">
                                    </el-image>
                                </div>
                                <div style="width: 60px;height: 60px;display: flex;align-items: center;" v-else>
                                    <span>暂无头像</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="rating" label="评分" width="200px">
                        </el-table-column>
                        <el-table-column prop="content" label="评论内容" width="200px">
                        </el-table-column>
                        <el-table-column prop="likes" label="点赞数" width="200px">
                        </el-table-column>
                        <el-table-column prop="" label="订单状态" width="200">
                                <template #default="scope">
                                    <span v-if="scope.row.orderStatus === 0"> <el-tag type="primary">未支付</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 1"><el-tag type="success">已支付</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 2"><el-tag type="danger">待发货</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 3"><el-tag type="primary">待发货</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 5"><el-tag type="info">已退款</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 6"><el-tag type="warning">申请退款</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 4"><el-tag type="success">已完成</el-tag></span>
                                </template>
                            </el-table-column>
                        <el-table-column prop="anonymous" label="是否匿名" width="200px">
                            <template #default="scope">
                                <span v-if="scope.row.anonymous == 1">是</span>
                                <span v-else-if="scope.row.anonymous == 0">否</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="updateTime" label="更新时间" width="200px">
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="200px">
                        </el-table-column>
                        <el-table-column prop="" fixed="right" label="操作" width="200px">
                            <template #default="scope">
                                <el-button type="danger" @click="commentDelete(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="commentfoot">
                    <div class="demo-pagination-blocks">
                        <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                            @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                            :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                            layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                        </el-pagination>
                    </div>
                </div>
                <div class="PopUps">
                <div v-if="showModals" class="commentmodeae">
                    <div class="modals-content">
                        <span class="close" @click="toggleModals">&times;</span>
                        <span class="edadmin">增加评论</span>
                        <el-form :model="form" label-width="auto" style="max-width: 30vw; margin-top: 5vh">
                            <el-form-item label="门店名">
                                <el-select v-model="value1" placeholder="请选择商品的门店" @change="addsd">
                                    <el-option v-for="item in store" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="分类名">
                                <el-select v-model="values" placeholder="请选择商品的分类" @change="addsds">
                                    <el-option v-for="item in managemente" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="商品名">
                                <el-select v-model="value" placeholder="请选择商品">
                                    <el-option v-for="item in commoditys" :key="item.value" :label="item.name"
                                        :value="item.id" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="订单ID">
                                <el-input
    v-model="input"
    style="width: 10vw"
    placeholder="订单id"
    clearable
  />
                            </el-form-item>
                            <el-form-item label="用户ID">
                                <el-input
    v-model="inputs"
    style="width: 10vw"
    placeholder="用户id"
    clearable
  />
                            </el-form-item>
                            <el-form-item label="评分">
                                <el-rate v-model="value2" :colors="colors" />
                            </el-form-item>
                            <el-form-item label="评论内容">
                                <el-input v-model="textarea2" style="width: 20vw"
                                    :autosize="{ minRows: 2, maxRows: 4 }" type="textarea" placeholder="商品评论" />
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="confirm">确认</el-button>
                                <el-button @click="toggleModals">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                </div>
                </div>  
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const loading = ref(true)
const region = ref('')
const userinput = ref('')
const input = ref('')
const showModals = ref(false)
const value1 = ref('')
const values = ref('')
const value = ref('')
const textarea2 = ref('')
const comment = ref([])
const comments = ref(null)
const inputs = ref('')
const tatol = ref(0)
const colors = ref(['#99A9BF', '#F7BA2A', '#FF9900'])
const value2 = ref('')
const page = reactive({ pageNum: 1, pageSize: 10 });

instance.get(`/api/comment/getComment?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
.then(response =>{
    console.log(response);
    comment.value = response.data.data.commentList
    comments.value = toRaw(comment.value)
    tatol.value = response.data.data.totalCount
    loading.value = false
}).catch(error =>{
    console.log(error);
})
const commentQuery = () => {
    console.log(region.value);
    console.log(userinput.value);
    // loading.value = true
    if (region.value === '1' && userinput.value !== '') {
        console.log('1');
        instance.get(`/api/wxComment/getCommentScore?productId=${userinput.value}`)
            .then(response => {
                console.log(response);
                comment.value = response.data.data
                comments.value = toRaw(comment.value)
                // tatol.value = response.data.data.totalCount
                // loading.value = false
            }).catch(error => {
                console.log(error);
            })
            return;
    }
    if (region.value === '2' && userinput.value !== '') {
        console.log('2');
        instance.get(`/api/wxComment/getCommentMediumReview?productId=${userinput.value}`)
        .then(response => {
                console.log(response);
                comment.value = response.data.data
                comments.value = toRaw(comment.value)
                // tatol.value = response.data.data.totalCount
                // loading.value = false
            }).catch(error => {
                console.log(error);
            })
            return;
    }
    if (region.value === '3' && userinput.value !== '') {
        console.log('3');
        instance.get(`/api/wxComment/getCommentNegative?productId=${userinput.value}`)
        .then(response => {
                console.log(response);
                comment.value = response.data.data
                comments.value = toRaw(comment.value)
                // tatol.value = response.data.data.totalCount
                // loading.value = false
            }).catch(error => {
                console.log(error);
            })
            return;
    }
    if ((region.value === '4' && userinput.value !== '') || (userinput.value !== '' && region.value === '')) {
        console.log('4');
        instance.get(`/api/wxComment/getComment?productId=${userinput.value}`)
        .then(response => {
                console.log(response);
                comment.value = response.data.data.commentEntities
                comments.value = toRaw(comment.value)
            }).catch(error => {
                console.log(error);
            })
            return;
    }
}
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    instance.get(`/api/comment/getComment?pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
.then(response =>{
    console.log(response);
    comment.value = response.data.data.commentList
    comments.value = toRaw(comment.value)
    tatol.value = response.data.data.totalCount
    loading.value = false
}).catch(error =>{
    console.log(error);
})
}
const commentAll = () => {
    showModals.value = true;
    console.log(showModals.value)
}
const toggleModals = () => {
    showModals.value = false;
    value1.value = ''
    values.value = ''
    value.value = ''
}
const stores = ref('');
const store = ref([]);
instance.get(`/api/stores`)
    .then(response => {
        stores.value = response.data;
        store.value = toRaw(stores.value)
        console.log(store.value);
    })
    .catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const searche = ref('')
const managemente = ref([])
const addsd = (id) => {
    console.log(id);
    instance.get(`/api/stores/cate/${id}`)
        .then(response => {
            console.log(id);
            searche.value = response.data.data;//获取数据
            managemente.value = toRaw(searche.value);
            console.log(managemente.value);
        }).catch(error => {
            console.log(error);
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请登录',
                type: 'error',
            })
            // router.push('/')

        })
}
const commodity = ref(null)
const commoditys = ref([])
const addsds = (id) => {
    console.log(id);
    console.log('llll');
    instance.get(`/api/categories/tp/${id}`)
        .then(response => {
            commodity.value = response.data.data;
            commoditys.value = toRaw(commodity.value);
            console.log(commoditys.value);
        }).catch(error => {
            console.log(error);
        })
}
const confirm = () => {
    console.log(value.value);
    console.log(textarea2.value);
    console.log(value2.value);
    console.log(input.value);
    console.log(inputs.value);
    const add = {
        content: textarea2.value,
        userId:inputs.value,
        orderId:input.value,
        productId:value.value,
        rating:value2.value,
    }
    instance.post('/api/wxComment/addComment',add)
    .then(response =>{
        console.log(response.data);
        ElMessage({
            showClose: true,
            message: '评论成功',
            type: 'success',
        })
        toggleModals();
        siftse();
    }).catch(error => {
            console.log(error);
        })
}


const commentDelete = (id)=>{
console.log(id);
ElMessageBox.confirm(
    '您确定要删除该评论嘛?',
    '提示',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
        instance.delete(`/api/comment/deleteComment?id=${id}`)
        .then(response =>{
            ElMessage({
        type: 'success',
        message: '删除成功',
      })
      siftse();
        }).catch(error => {
            console.log(error);
        }
    )
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '取消',
      })
    })
}
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.demo-rate-block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 49%;
  box-sizing: border-box;
}
.demo-rate-block:last-child {
  border-right: none;
}
.demo-rate-block .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
.comment {
    margin-left: -4vw;
    margin-top: 7vh;
    width: 100vw;
    height: 100vh;
}

.commentbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.commentfoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.commenthead {
    margin-top: 3vh;
}

.commentbody .el-table--border .el-table__inner-wrapper::after {
    z-index: 1;
}

.commentSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.commentSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.commentfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}

.PopUps  .commentmodeae .edadmin {
    font-size: 16px;
}

.PopUps .commentmodeae {
    display: block;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
.commentmodeae .el-form-item{
    margin-bottom: 5vh;
}
.commentbody .el-table .cell {
    width: 200px !important;
}
</style>