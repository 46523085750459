<!-- 商品详情 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="Pdetails">
                        <div class="demo-image__lazy" scroll-container>
                            <el-image v-for="(item, index) in pdetails" :key="index" :src="image[index]"
                                lazy></el-image>
                        </div>
                        <el-button type="primary" @click="returns">返回</el-button>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'

const router = useRouter();
const pdetails = ref([]);
const pdetail = ref(null);
const goods = useRoute();
const images = ref([])
const image = ref([])

console.log(goods.query.msg);
instance.get('/api/tproduct/details/' + goods.query.msg)
    .then(response => {
        console.log(1);
        if (response.data.data.length !== 0) {
            pdetail.value = response.data.data;
            console.log(response.data.data);
            // pdetails.value = toRaw(pdetail.value);
            const length = ref(Object.keys(pdetail.value[0]).length);
            console.log(length.value);
            for (let i = 0; i < length.value; i++) {
                // console.log(1);
                const dataArray = ref(Object.values(pdetail.value[0]));
                console.log(dataArray.value[i]);
                if (dataArray.value[i] !== '' && dataArray.value[i] !== null && dataArray.value[i] !== undefined) {
                    pdetails.value.splice(i, pdetails.value.length)
                    pdetails.value.push(dataArray.value[i]);
                    console.log(pdetails.value);
                }
            }
            for (let i = 0; i < pdetails.value.length; i++) {
                images.value.splice(i, images.value.length)
                images.value.push(pdetails.value[i])
            }
            fetchData();
            console.log(pdetails.value);
        } else {
            ElMessage({
                showClose: true,
                message: '暂无详情，请添加',
                type: 'error',
            })
            return router.push('./commodity')
        }
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        // router.push('./')
    })
const returns = () => {
    router.push('./Commodity')
}
async function fetchData() {

    console.log(image.value);
    image.value.splice(0, image.value.length);
    // image.value = []
    for (let j = 0; j < images.value.length; j++) {
        console.log('111');
        try {
            const response = await instance.get(`/api/common/download?name=${images.value[j]}`);
            console.log('333');
            image.value.splice(j, image.value.length);
            image.value.push(response.request.responseURL);
            console.log(image.value);
        } catch (error) {
            console.log(error);
        }
    }
    console.log(image.value);
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.Pdetails {
    position: fixed;
    font-size: 16px;
    width: 100vw;
    left: 42vw;
    top: 20vh;
}

.Pdetails .demo-image__lazy {
    width: 25vw;
    height: 50vh;
    /* background-color: red; */
    overflow-y: auto;
}

.Pdetails .el-image {
    width: 25vw;
    height: 50vh;
    background-color: red;
}

.Pdetails .el-button {
    justify-content: center;
    margin-left: 10vw;
}
</style>