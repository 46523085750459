<!-- 草稿箱 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="announcements">
                        <el-table :data="draft" height="75vh" style="width: 100%" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column prop="title" label="标题" width="80" />
                            <el-table-column label="内容" width="300">
                                <template #default="scope">
                                    <div v-if="scope.row.mediaType !== 2" v-html="scope.row.content"></div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" width="180" />
                            <el-table-column prop="mediaUrl" label="发布视频或图片" width="200">
                                <template #default="scope">
                                    <div style="width: 50px;height: 50px;display: flex;align-items: center;">
                                        <el-image fit="fill" lazy v-if="scope.row.mediaType === 1"
                                            :src="`https://199shq.com/api/common/download?name=${scope.row.mediaUrl}`">
                                        </el-image>
                                        <video style="width: 200px ;height: 80px;" v-if="scope.row.mediaType === 2"
                                            :src="`http://199cstorage.com/${scope.row.mediaUrl}`"
                                            controls></video>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="操作" width="180">
                                <template #default="scope">
                                    <el-button type="primary" @click="handleEdit(scope.row.id)">编辑</el-button>
                                    <el-button type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw } from 'vue';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const draft = ref([])
const drafts = ref(null)
const images = ref([])
const image = ref([])
const loading = ref(true)

instance.get('/api/announcement/getAnnouncementList')
    .then(res => {
        drafts.value = res.data.data
        console.log(drafts.value);
        draft.value = toRaw(drafts.value)
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    })

const handleDelete = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '您确定要删除该草稿吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            loading.value = true
            instance.delete(`/api/announcement/deleteAnnouncement?id=${id}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    instance.get('/api/announcement/getAnnouncementList')
                        .then(res => {
                            drafts.value = res.data.data
                            console.log(drafts.value);
                            draft.value = toRaw(drafts.value)
                            loading.value = false
                        }).catch(error => {
                            console.log(error);
                            ElMessage({
                                showClose: true,
                                message: '请登录',
                                type: 'error',
                            })
                            router.push('/')
                        })
                }).catch((error) => {
                    console.log(error);
                })
                .catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '取消删除',
                    })
                })
        })
}
const handleEdit = (id) => {
    router.push({
        path: '/editannouncement',
        query: {
            id: id
        }
    })
}

</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}
</style>