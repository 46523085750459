<!-- 管理员 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="manage">
                        <el-table :data="manage" height="400" style="width: 100%" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column prop="name" label="管理员用户名" width="180" />
                            <el-table-column prop="account" label="账号" width="180" />
                            <el-table-column prop="" label="管理员身份及门店id" width="180">
                                <template #default="scope">
                                    <span v-if="scope.row.permissionsStatus === 0">超管</span>
                                    <span v-else>门店{{ scope.row.sysStoreId }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" label="创建时间" />
                            <el-table-column label="开始">
                                <template #default="scope">
                                    <el-switch size="large" active-text="" inactive-text=""
                                        v-model="scope.row.loginStatus"
                                        @change="admin(scope.row.loginStatus, scope.row.id)" />
                                </template>
                            </el-table-column>
                            <el-table-column prop="" label="操作">
                                <template #default="scope">
                                    <el-button type="primary" size="small" @click="edit(scope.row.id)">编辑</el-button>
                                    <el-button type="danger" size="small" @click="del(scope.row.id)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div v-if="showModals" class="modea">
                        <div class="modals-content">
                            <span class="close" @click="toggleModals">&times;</span>
                            <span class="edadmin">修改管理员账号</span>
                            <el-form :model="form" label-width="auto" style="max-width: 30vw">
                                <el-form-item label="账号名">
                                    <el-input v-model="form.name" />
                                </el-form-item>
                                <el-form-item label="账号">
                                    <el-input v-model="form.account" />
                                </el-form-item>
                                <el-form-item label="密码">
                                    <el-input v-model="form.password" />
                                </el-form-item>
                                <el-form-item label="所属门店">
                                    <el-select v-model="form.sysStoreId" placeholder="商品所属门店" @change="changeStore">
                                        <el-option v-for="item in stores" :key="item.id" :label="item.name"
                                            :value="item.id" />
                                        <el-option label="超管" value="0" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="confirm">确认</el-button>
                                    <el-button @click="cancellation">取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import { ref, toRaw } from 'vue'

const manage = ref([])
const manages = ref(null)
const router = useRouter()
const loading = ref(true)
const showModals = ref(false)

instance.get('/api/admin/getAdministrator')
    .then(response => {
        console.log(response.data.data);
        manages.value = response.data.data
        console.log(manages.value);
        // manage.value = toRaw(manages.value)
        console.log(manage.value);
        for (let i = 0; i < manages.value.length; i++) {
            if (manages.value[i].loginStatus === 0) {
                manages.value[i].loginStatus = true
            } else {
                manages.value[i].loginStatus = false
            }
        }
        console.log(manages.value);
        manage.value = toRaw(manages.value)
        loading.value = false

    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('./')
    })
const admin = (loginStatus, id) => {
    loading.value = true
    if (loginStatus === true) {
        loginStatus = 0
    } else {
        loginStatus = 1
    }
    instance.put(`/api/admin/startAndClose?id=${id}&loginStatus=${loginStatus}`)
        .then(response => {
            if (loginStatus === 0) {
                ElMessage({
                    type: 'success',
                    message: '已开启',

                })
            } else {
                ElMessage({
                    type: 'success',
                    message: '已关闭',

                })
            }

            loading.value = false
            console.log(response);
            // window.location.reload()
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}

const form = ref({
    name: '',
    AccountNumber: '',
    password: '',
    sysStoreId: '',
})

const EditUser = ref([])
const EditUsers = ref(null)
const stores = ref([])
const storess = ref(null)
const edit = (id) => {
    showModals.value = !showModals.value;
    instance.get(`/api/admin/getUserId?id=${id}`)
        .then(response => {
            EditUsers.value = response.data.data
            EditUser.value = toRaw(EditUsers.value)
            console.log(EditUser.value);
            if (EditUser.value.permissionsStatus === 0) {
                EditUser.value.sysStoreId = '超管'
            }

            form.value = {
                "id": EditUser.value.id,
                "name": EditUser.value.name,
                "account": EditUser.value.account,
                "sysStoreId": EditUser.value.sysStoreId,
                "password": '',
                "permissionsStatus": EditUser.value.permissionsStatus,
            }
            console.log(form.value);
            instance.get(`/api/stores`)
                .then(response => {
                    storess.value = response.data;//获取数据
                    stores.value = toRaw(storess.value);
                    console.log(stores.value);
                })
        })
        .catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
        })
}

const changeStore = () => {

}
console.log(form.value);
const toggleModals = () => {
    showModals.value = !showModals.value;
};
const confirm = () => {
    console.log(form.value);
    if (form.value.password === '') {
        ElMessage({
            showClose: true,
            message: '请填写密码',
            type: 'error',
        })
        return;
    }
    console.log(form.value.sysStoreId);
    if (form.value.sysStoreId !== '0') {
        form.value.permissionsStatus = 1
        form.value.sysStoreId = form.value.sysStoreId
        console.log('22222');
    }
    if (form.value.sysStoreId === '0') {
        form.value.sysStoreId = null
        form.value.permissionsStatus = 0
        console.log('1111');
    }
    console.log(form.value);
    instance.put(`/api/admin/editUser`, form.value)
        .then(response => {
            ElMessage({
                type: 'success',
                message: '编辑成功',

            })
            instance.get('/api/admin/getAdministrator')
                .then(response => {
                    console.log(response.data.data);
                    manages.value = response.data.data
                    console.log(manages.value);
                    // manage.value = toRaw(manages.value)
                    console.log(manage.value);
                    for (let i = 0; i < manages.value.length; i++) {
                        if (manages.value[i].loginStatus === 0) {
                            manages.value[i].loginStatus = true
                        } else {
                            manages.value[i].loginStatus = false
                        }
                    }
                    console.log(manages.value);
                    manage.value = toRaw(manages.value)
                    loading.value = false

                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('./')
                })
        }).catch(error => {
            console.log(error);
            ElMessage({
                showClose: true,
                message: '编辑失败',
                type: 'error',
            })
        })
}
const cancellation = () => {
    showModals.value = false;
}
const del = (id) => {
    ElMessageBox.confirm(
        '您确定要删除账号吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.delete(`/api/admin/deleteUser?id=${id}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    instance.get('/api/admin/getAdministrator')
                        .then(response => {
                            console.log(response.data.data);
                            manages.value = response.data.data
                            console.log(manages.value);
                            // manage.value = toRaw(manages.value)
                            console.log(manage.value);
                            for (let i = 0; i < manages.value.length; i++) {
                                if (manages.value[i].loginStatus === 0) {
                                    manages.value[i].loginStatus = true
                                } else {
                                    manages.value[i].loginStatus = false
                                }
                            }
                            console.log(manages.value);
                            manage.value = toRaw(manages.value)
                            loading.value = false

                        }).catch(error => {
                            console.log(error);
                            ElMessage({
                                showClose: true,
                                message: '请登录',
                                type: 'error',
                            })
                            router.push('./')
                        })
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        type: 'error',
                        message: '删除失败',

                    })
                    router.push('/')
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })

}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.manage {
    width: 85vw;
    height: 100vh;
    margin-top: 15vh;
}


.modals-content {
    background-color: #fefefe;
    margin: 8% 15% 15% 35%;
    padding: 20px;
    border: 1px solid #888;
    width: 400px;
    left: 50vw;
}

.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modea .edadmin {
    font-size: 16px;
}

.modea {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>