<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="campus">
                        <div class="campushead">
                            <el-button type="primary" @click="addcampus">添加院区</el-button>
                        </div>
                        <div class="campusbody">
                            <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="campus"
                                :border="parentBorder" style="width: 100%" height="400">
                                <el-table-column type="expand">
                                    <template #default="props">
                                        <div m="4">
                                            <el-table :data="props.row.departmentList" :border="childBorder"
                                                style="left: 2%;">
                                                <el-table-column type="expand">
                                                    <template #default="props">
                                                        <div m="4">
                                                            <el-table :data="props.row.floorList" :border="childBorder"
                                                                style="left: 4%;">
                                                                <el-table-column label="楼层" prop="number" />
                                                                <el-table-column label="操作">
                                                                    <template #default="scope">
                                                                        <el-button size="small" type="primary"
                                                                            @click="Editfloor(scope.row.departmentId, scope.row.id)">编辑楼层
                                                                        </el-button>
                                                                        <el-button size="small" type="danger"
                                                                            @click="Deletefloor(scope.row.id)">删除楼层</el-button>
                                                                    </template>
                                                                </el-table-column>
                                                            </el-table>
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="科室" prop="departmentName" />
                                                <el-table-column label="操作">
                                                    <template #default="scope">
                                                        <el-button size="small" type="primary"
                                                            @click="EditorialSection(scope.row.departmentsId, scope.row.departmentCourtyardId)">编辑科室
                                                        </el-button>
                                                        <el-button size="small" type="danger"
                                                            @click="DeleteDepartment(scope.row.departmentsId)">删除科室</el-button>
                                                        <el-button size="small" type="primary"
                                                            @click="NewFloors(scope.row.departmentsId, scope.row)">新增楼层
                                                        </el-button>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="院区" prop="name" />
                                <el-table-column label="操作">
                                    <template #default="scope">
                                        <el-button type="primary" size="small"
                                            @click="handleEdit(scope.row.id)">编辑院区</el-button>
                                        <el-button type="danger" size="small"
                                            @click="handleDelete(scope.row)">删除院区</el-button>
                                        <el-button type="primary" size="small"
                                            @click="handleAdd(scope.row.id)">新增科室</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { useRouter } from 'vue-router'
import { ref, toRaw } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'

const parentBorder = (false)
const campus = ref([])
const campuse = ref(null)
const childBorder = ref(false)
const loading = ref(true)
const router = useRouter()

instance.get('/api/dispatching/getHospitalData')
    .then(response => {
        campuse.value = response.data
        campus.value = toRaw(campuse.value)
        console.log(campus.value);
        loading.value = false
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })

// const handleAdd = (id) => {
// instance.post(`/api/dispatching/insertDepartment/${id}`)
//     .then(response => {

//     }).catch(error => {

//     })
// }

const addcampus = () => {
    ElMessageBox.prompt('新增院区', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            // console.log(id);
            const addcampuss = {
                "name": value,
                "id": 0
            }
            console.log(addcampuss);
            instance.post(`/api/dispatching/insertCourtyard`, addcampuss)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `新增成功,院区名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消新增',
            })
        })
}
const handleAdd = (id) => {
    ElMessageBox.prompt('新增科室', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            // console.log(id);
            const Newdepartments = {
                "courtyardId": id,
                "departmentName": value,
                "id": 0
            }
            console.log(Newdepartments);
            instance.post(`/api/dispatching/insertDepartment`, Newdepartments)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `新增成功,科室名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消新增',
            })
        })
}
const handleEdit = (id) => {
    console.log(id);
    ElMessageBox.prompt('编辑院区', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            // console.log(id);
            const Newhospital = {
                "id": id,
                "name": value,
            }
            console.log(Newhospital);
            instance.put(`/api/dispatching/updateCourtyard`, Newhospital)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `编辑成功,院区新名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消新增',
            })
        })
}
const handleDelete = (row) => {
    console.log(row.id);
    ElMessageBox.confirm(
        '是否要删除该院区吗?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
        }
    )
        .then(() => {
            console.log(row.id);
            instance.delete(`/api/dispatching/deleteCourtyard?id=${row.id}`)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    window.location.reload()
                })
                .catch(error => {
                    ElMessage({
                        type: 'error',
                        message: '删除失败，请先删除该院区下面的科室',
                    })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const EditorialSection = (departmentsId, departmentCourtyardId) => {
    console.log(departmentsId);
    console.log(departmentCourtyardId);
    ElMessageBox.prompt('编辑科室', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            const EditorialSectionas = {
                "id": departmentsId,
                "courtyardId": departmentCourtyardId,
                "departmentName": value,
            }
            console.log('111111');
            instance.put(`/api/dispatching/updateDepartment`, EditorialSectionas)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `编辑成功,科室新名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消编辑',
            })
        })
}
const NewFloors = (departmentsId) => {
    console.log(departmentsId);
    ElMessageBox.prompt('新增楼层', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            const NewFloorsse = {
                "departmentId": departmentsId,
                "number": value,
                "id": 0
            }
            console.log(NewFloorsse);
            instance.post(`/api/dispatching/insertFloor`, NewFloorsse)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `新增成功,楼层名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消新增',
            })
        })
}
const DeleteDepartment = (departmentsId) => {
    console.log(departmentsId);
    ElMessageBox.confirm(
        '是否要删除该科室,并删除下面所有数据?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
        }
    )
        .then(() => {
            instance.delete(`/api/dispatching/deleteDepartment?id=${departmentsId}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    window.location.reload()
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
const Editfloor = (departmentId, id) => {
    console.log(departmentId, id);
    ElMessageBox.prompt('编辑楼层', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            console.log(value);
            console.log(id);
            const Editfloorss = {
                "id": id,
                "departmentId": departmentId,
                "number": value,
            }
            console.log(Editfloorss);
            instance.put(`/api/dispatching/updateFloor`, Editfloorss)
                .then(response => {
                    ElMessage({
                        type: 'success',
                        message: `编辑成功,楼层新名为${value}`,
                    })
                    window.location.reload()
                }).catch(error => {
                    console.log(error);
                })
        })

        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消编辑',
            })
        })
}
const Deletefloor = (id) => {
    console.log(id);
    ElMessageBox.confirm(
        '是否要删除该楼层?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            center: true,
        }
    )
        .then(() => {
            console.log(id);
            instance.delete(`/api/dispatching/deleteFloor?id=${id}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '删除成功',
                    })
                    window.location.reload()
                })
                .catch(error => {
                    console.log(error);
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.campus {
    width: 85vw;
    height: 100vh;

}

/* .campusbody{
    margin-top: 20vh;
} */
.campushead {
    margin-left: 60vw;
}
</style>