<!-- 资金流水 -->
<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="flowtop">
                        <span>用户ID：</span>
                        <span> <el-input v-model="input" style="width: 240px" placeholder="用户ID"
                                @keyup.enter="search" /></span>
                        <span class="Separateds">日期范围：</span><el-date-picker class="select" v-model="value2"
                            type="datetimerange" :picker-options="pickerOptions" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" align="right">
                        </el-date-picker>
                        <el-button type="primary" class="querys" @click="query">查询</el-button>
                        <span class="Separateds">筛选类型：</span>
                        <span class="inputs">
                            <el-select v-model="value" clearable  placeholder="请选择商品的门店" @change="screen">
                                <el-option v-for="item in classifse" :key="item.value" :label="item.name"
                                    :value="item.id" />
                            </el-select>
                        </span>
                        <span><el-button type="primary" class="Separateds" @click="exports">导出</el-button></span>
                    </div>
                    <div class="flowbottom">
                        <el-table :data="flow" style="width: 95%" height="70vh" v-loading="loading"
                            element-loading-text="加载中，请稍后...">
                            <el-table-column fixed prop="wxuserId" label="用户ID" width="200" />
                            <el-table-column prop="nickName" label="用户昵称" width="200" />orderStatus
                            <el-table-column prop="orderPrice" label="金额" width="200" />
                            <el-table-column prop="" label="订单状态" width="200">
                                <template #default="scope">
                                    <span v-if="scope.row.orderStatus === 0"> <el-tag type="primary">未支付</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 1"><el-tag type="success">已支付</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 2"><el-tag type="danger">待发货</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 3"><el-tag type="primary">待发货</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 5"><el-tag type="info">已退款</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 6"><el-tag type="warning">申请退款</el-tag></span>
                                    <span v-if="scope.row.orderStatus == 4"><el-tag type="success">已完成</el-tag></span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="name" label="类型" width="200" />
                            <el-table-column prop="storeName" label="商品门店" width="200" />
                            <el-table-column prop="message" label="备注" width="200" />
                            <el-table-column prop="id" label="订单号" width="200" />
                            <el-table-column prop="goodsName" label="商品名称" width="200" />
                            <el-table-column prop="inviteId" label="推荐人" width="200" />
                            <el-table-column prop="goodsNumber" label="数量" width="200" />
                            <el-table-column prop="" label="支付单号" width="200" />
                            <el-table-column prop="" label="支付时间" width="200" />
                            <el-table-column prop="addOrderTime" label="订单创建时间" width="200" />
                        </el-table>
                    </div>
                    <div class="flowfoot">
                        <div class="demo-pagination-blocks">
                            <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                                :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ref, toRaw, reactive } from "vue";
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'

const input = ref('');
const value2 = ref('')
const value = ref('')
const flow = ref('')
const flows = ref(null)
const router = useRouter();
const route = useRoute();
const loading = ref(true)
const classifysa = ref([])
const classifse = ref(null)
const tatol = ref(0);
const page = reactive({ pageNum: 1, pageSize: 10 });

instance.get('/api/dispatching/getDispatchingList')
    .then(response => {
        console.log(response);
        tatol.value = response.data.data.length
        console.log(tatol.value);
        loading.value = false
    })
    .catch(error => {
        console.log(error);
        console.error(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
instance.get(`/api/dispatching/selectPaging?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
    .then(response => {
        flows.value = response.data.data
        flow.value = toRaw(flows.value)
        console.log(flow.value);
        loading.value = false
    })
    .catch(error => {
        console.log(error);
        console.error(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
instance.get('/api/stores')
    .then(response => {
        console.log(response.data)
        classifse.value = response.data
    }).catch(error => {
        console.log(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    })
const query = () => {
    siftse();

}
const screen = () => {
    console.log(value.value);
    siftse();
}
const exports = () => {
    const ch = Array.isArray(value2.value)
    // 导出全部
    if (value.value === '' && input.value === '' && ch === false) {
        instance.get('/api/dispatching/exportAllFinanceOrders', { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                // router.push('./')
            })
        return;
    }
    //导出用户ID
    if (input.value !== '' && value.value === '' && ch === false) {
        instance.get(`/api/dispatching/searchDerived?wxuserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })

        return;
    }
    //导出商品类型
    if (input.value === '' && value.value !== '' && ch === false) {
        instance.get(`/api/dispatching/searchDerived?name=${value.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })

        return;

    }

    //导出商品类型和用户id
    if (input.value !== '' && value.value !== '' && ch === false) {
        instance.get(`/api/dispatching/searchDerived?name=${value.value}&wxuserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })
        return;
    }
    //导出日期
    if (input.value === '' && value.value === '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/searchDerived?endDate=${chineseTimea.value}&startDate=${chineseTime.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })
        return;
    }
    //导出日期和用户ID
    if (input.value !== '' && value.value === '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/searchDerived?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&wxuserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })
        return;
    }
    // 导出日期和类型
    if (input.value === '' && value.value !== '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/searchDerived?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })

        return;
    }
    //导出日期和类型和用户ID
    if (input.value !== '' && value.value !== '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/searchDerived?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}&wxuserId=${input.value}`, { responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', '资金流水.xlsx'); // 设置要下载的文件名
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url); // 释放URL对象
                ElMessage({
                    message: '导出成功',
                    type: 'success',
                });
            })
            .catch(error => {
                console.log(error);
            })
    }
}
const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const search = () => {
    siftse();
}

const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    console.log(value.value);
    console.log(input.value);
    console.log(Array.isArray(value2.value));
    const ch = Array.isArray(value2.value)
    console.log(ch);
    //搜索全部
    if (value.value === '' && input.value === '' && ch === false) {
        instance.get('/api/dispatching/getDispatchingList')
            .then(response => {
                tatol.value = response.data.data.length
                loading.value = false
                console.log('全部');
            })
            .catch(error => {
                console.log(error);
                console.error(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                router.push('./')
            })
        instance.get(`/api/dispatching/selectPaging?&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                console.log(flow.value);
                loading.value = false
            }).catch(error => {
                console.log(error);
                ElMessage({
                    message: '请重新登录',
                    type: 'error',
                })
                router.push('./')
            })
        return;
        //搜索用户ID
    }
    if (input.value !== '' && value.value === '' && ch === false) {
        instance.get(`/api/dispatching/selectFuzzyQuery?wxuserId=${input.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(flow.value);
                console.log('用户ID');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?wxuserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                console.log(flow.value);
                loading.value = false
            })
            .catch(error => {
                console.log(error);
            })
        return;
    }
    //搜索商品类型
    if (input.value === '' && value.value !== '' && ch === false) {
        instance.get(`/api/dispatching/selectFuzzyQuery?name=${value.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(tatol.value);
                console.log(flow.value);
                console.log('类型');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?name=${value.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                console.log(flow.value);
                console.log('22222');
                loading.value = false
            })
            .catch(error => {
                console.log(error);
            })
        return;

    }

    //搜索商品类型和用户id
    if (input.value !== '' && value.value !== '' && ch === false) {
        instance.get(`/api/dispatching/selectFuzzyQuery?name=${value.value}&wxuserId=${input.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(flow.value);
                console.log('类型和用户ID');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?name=${value.value}&wxuserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                console.log(flow.value);
                console.log('22222');
                loading.value = false
                console.log('类型和用户ID');
            })
            .catch(error => {
                console.log(error);
            })
        return;
    }
    //搜索日期
    if (input.value === '' && value.value === '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/selectFuzzyQuery?endDate=${chineseTimea.value}&startDate=${chineseTime.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(flow.value);
                console.log('日期');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                console.log(response.data.data.length);
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
        return;
    }
    //搜索日期和用户ID
    if (input.value !== '' && value.value === '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/selectFuzzyQuery?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&wxuserId=${input.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(tatol.value);
                console.log('日期和用户ID');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&wxuserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {

                console.log(response.data.data.length);
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
        return;
    }
    // 搜索日期和类型
    if (input.value === '' && value.value !== '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/selectFuzzyQuery?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(flow.value);
                console.log('日期和类型');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {

                console.log(response.data.data.length);
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
        return;
    }
    if (input.value !== '' && value.value !== '' && ch === true) {
        const chineseTime = ref('');
        const date = new Date(value2.value[0]);
        console.log(date);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        console.log(hours, minutes, seconds);
        chineseTime.value = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        console.log(chineseTime.value);


        const chineseTimea = ref('');
        const datea = new Date(value2.value[1]);
        console.log(datea);
        const yeara = datea.getFullYear();
        const montha = String(datea.getMonth() + 1).padStart(2, '0');
        const daya = String(datea.getDate()).padStart(2, '0');
        const hoursa = String(date.getHours()).padStart(2, '0');
        const minutesa = String(date.getMinutes()).padStart(2, '0');
        const secondsa = String(date.getSeconds()).padStart(2, '0');
        chineseTimea.value = `${yeara}-${montha}-${daya} ${hoursa}:${minutesa}:${secondsa}`;
        console.log(chineseTimea.value);
        instance.get(`/api/dispatching/selectFuzzyQuery?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}&wxuserId=${input.value}`)
            .then(response => {
                if (response.data.data.length == 0) {
                    return ElMessage({
                        message: '没有该时期流水',
                    })
                }
                tatol.value = response.data.data.length
                console.log(flow.value);
                console.log('日期和用户ID，类型');
            })
            .catch(error => {
                console.log(error);
            })
        instance.get(`/api/dispatching/searchPaging?endDate=${chineseTimea.value}&startDate=${chineseTime.value}&name=${value.value}&wxuserId=${input.value}&pageNum=${page.pageNum}&pageSize=${page.pageSize}`)
            .then(response => {
                console.log(response.data.data.length);
                flows.value = response.data.data
                flow.value = toRaw(flows.value)
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
        return;
    }
}

</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.main_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
}

.flowtop {
    width: 90vw;
    height: 20vh;
    top: 10vh;
    font-size: 1vw;
    position: fixed;
    /* background-color: aqua; */
}

.Separateds {
    margin-left: 2vw;
}

.select {
    width: 20vw !important;
}

.querys {
    margin-top: -1.5vh;
}

.flowmiddle {
    width: 90vw;
    height: 10vh;
    top: 25vh;
    left: 16vw;
    font-size: 1vw;
    position: fixed;
}

.flowbottom {
    width: 100vw;
    height: 70vh;
    margin-left: 6vw;
    margin-top: -15vh;
}

.flowbottom .el-table .cell {
    width: 200px !important;
}

.flowfoot {
    width: 100vw;
    height: 10vh;
}

.flowfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    top: 93vh;
    height: 10vh;
    left: 25vw;
    /* background-color: red; */
}

.inputs .el-select {
    width: 10vw;
}
</style>