<template>
  <div class="pages">
    <div class="login-page">
      <div class="glass-effect">
        <h1>登录</h1>
        <form @submit.prevent="login" class="from">
          <input type="text" class="user" v-model="username" placeholder="  用户名" required>
          <input type="password" class="password" v-model="password" placeholder="  密码" required>
          <button type="submit" class="btn" @click="submit" @keyup.enter.native="submit">登录</button>
        </form>
      </div>

      <div class="footer">
        版权公告 © 2022年至今 汉方生命科技(广州)有限公司。版权所有。
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">粤ICP备2023103141号-1</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import Axios from "axios"
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, beforeCreate } from 'vue'
import instance from '../network/storester.js'

onMounted(() => {
  document.title = '汉方后台'
})
Axios.defaults.baseURL = "/api";

const router = useRouter();
const route = useRoute();

const username = ref('');
const password = ref('');
const token = localStorage.getItem('token')
console.log(localStorage.getItem('user'));
const user = localStorage.getItem('user')
// const user = JSON.parse(sessionStorage.getItem('user'));
console.log(user);
// onMounted(() => {
//   if (token) {
//     console.log("已存在登录")
//     router.push('/index')
//     return;
//   }
// })
console.log(token);
const submit = () => {
  if (username.value != '' && password.value != '') {
    const data = {
      "account": username.value,
      "password": password.value
    }
    Axios({
      method: "post", //接口方法

      url: "/api/domain/login", //服务器接口
      // url: "/domain/login", //本地接口

      data

    }).then((response) => {
      console.log(response);
      if (response.data.code == 0) {
        ElMessage.error('登录失败请检查用户名和密码是否正确,如果还是登录失败则可能账号被锁定，请及时与管理员联系')
      }
      if (response.data.code == 200) {
        localStorage.setItem('myData', response.data.data.name);
        localStorage.setItem('permissionsStatus', response.data.data.permissionsStatus);
        localStorage.setItem('sysStoreId', response.data.data.sysStoreId);
        localStorage.setItem('msg', response.data.data.id)
        localStorage.setItem('name', response.data.data.name)
        localStorage.setItem('account', response.data.data.account)
        localStorage.setItem('token', response.data.data.token)
        localStorage.setItem('user', JSON.stringify(response.data.data))
        console.log(response.data.data.account);
        //session保存
        window.sessionStorage.setItem("token", response.data.data.token);

        router.push({
          path: '/index',
          query: {
            msg: localStorage.getItem('msg')
          }
        });
        ElMessage({
          message: `欢迎${localStorage.getItem('name')}`,
          type: 'success',
        })
        instance.get('/api/inventory/getAuthID')
          .then(response => {
            console.log(response.data.data);
            console.log(response.data.data.authID);
            localStorage.setItem('authID', response.data.data.authID)
            // const msg = response.data.data.id;
          }).catch(error => {
            console.log(error);
            ElMessage({
              message: `身份过期`,
              type: 'error',
            })
          })
        console.log(response.data.data);

      }
    }).catch(error => {
      console.log(error);
    })

    // axios.post('https://199shq.com/api/domain/login',JSON.stringify(data),{headers: {'Content-Type': 'application/json'}})
    // .then(response => {
    //     console.log(response.data);
    //     if (response.data.code == 0) {
    //       ElMessage.error('登录失败请检查用户名和密码是否正确,如果还是登录失败则可能账号被锁定，请及时与管理员联系')
    //     }

    //     if (response.data.code == 200) {
    //       localStorage.setItem('myData', response.data.data.name);
    //       localStorage.setItem('permissionsStatus', response.data.data.permissionsStatus);
    //       localStorage.setItem('sysStoreId', response.data.data.sysStoreId);
    //       //session保存
    //       window.sessionStorage.setItem("token",response.data.data.createTime);
    //       const msg = response.data.data.id;
    //       router.push({
    //           path:'/index',
    //           query: {
    //             msg: msg
    //           }
    //       });
    //       ElMessage({
    //           message: `欢迎${response.data.data.name}`,
    //           type: 'success',
    //       })
    //     }
    // })
    // .catch(error => {
    //     console.error(error);
    // });
  } else {
    ElMessage({
      message: '请填写用户名和密码',
      type: 'warning',
    })
  }
}

// }

</script>

<style scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-align: center;
  font-size: 14px;
}

.pages {
  width: 99vw;
  height: 100vh;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url("../assets/login_bg.png");
  background-size: cover;
}

.glass-effect {
  width: 20vw;
  height: 40vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h1 {
  text-align: center;
}

form {
  height: 30vh;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user {
  margin-top: 5vh;
  height: 5vh;
  font-size: 16px;
  border: 2px slateblue solid;
  border-radius: 20px;
}

.password {
  margin-top: 2vh;
  height: 5vh;
  font-size: 16px;
  border: 2px slateblue solid;
  border-radius: 20px;
}

.btn {
  margin-top: 6vh;
  height: 6vh;
  color: aliceblue;
  background: linear-gradient(to right, #8a2be2, #0000ff);
  border: 2px slateblue solid;
  border-radius: 20px;
  font-size: 18px;
  line-height: 3vh;
}

input {
  padding: 5px;
}

button {
  padding: 10px;
}
</style>