<!-- 新增视频 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="addvideo">
                <p>
                    <span class="name">视频简介:</span>
                    <span class="input"> <el-input v-model="textarea" maxlength="100" placeholder="请输入视频的介绍"
                            show-word-limit type="textarea" /></span>
                </p>
                <p>
                    <span class="name"><i style="color: red;">*</i>视频分类：</span>
                    <el-select v-model="values" placeholder="请选择视频的分类" @change="video">
                        <el-option v-for="item in managementes" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-select>
                </p>
                <p>
                    <span class="name">商品详情视频</span>
                    <span><el-upload class="upload-demo" action="" :before-upload="beforeAvatarUploadsd" multiple
                            :limit="3">
                            <video v-if="videoUrl !== ''" :src="videoUrl" controls autoplay></video>
                            <el-button v-else type="primary">点击上传</el-button>
                        </el-upload></span>
                </p>
                <p>
                    <span> <el-button @click="upload" type="primary">上传</el-button></span>
                    <span class="bus"><el-button @click="concel" type="primary">返回</el-button></span>
                </p>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'
import { ref } from 'vue'

const router = useRouter()
const textarea = ref('');
const videoUrl = ref('')
const namea = ref('')
//上传视频
const beforeAvatarUploadsd = (file) => {
    console.log(file.type);
    if (file.type !== 'video/mp4') {
        ElMessage({
            type: 'error',
            message: '请上传mp4格式视频',
        })
        return;
    }
    const formData = new FormData();
    formData.append('file', file);
    instance.post('/api/common/uploadVideo', formData)//上传api
        .then(response => {
            console.log('上传成功');
            console.log(file);
            console.log(response.data.data);
            namea.value = response.data.data;
            videoUrl.value = URL.createObjectURL(file)
            console.log(namea.value);
        }).catch(response => {
            console.log(response);
            ElMessage({
                showClose: true,
                message: '图片上传失败，请联系管理员重启分配资源',
                type: 'error',
            })
        })

    console.log(formData);
    const isLt2M = file.size / 1024 / 1024 < 200;
    const timer = ref(null);
    timer.value = setTimeout(() => {
        // 执行需要停止的操作
        alert('响应时间超过规定时间');
    }, 10000);
    clearTimeout(timer.value);

    if (!isJPG) {
        alert('上传头像图片只能是 JPG 格式!');
    }
    // if (!isLt2M) {
    //     alert('上传头像图片大小不能超过 2MB!');
    // }
    console.log("1111");
    return isJPG && isLt2M;
};
const concel = () => {
    router.push('./operate')//返回
}
const values = ref('')
//设置分类
const managementes = [
    {
        value: '1',
        label: '开心一笑',
    },
    {
        value: '2',
        label: '老年人健康',
    }
]
const titleId = ref('')
//保存选中的选项value
const video = (value) => {
    titleId.value = value
}

//最终确定上传视频文件
const upload = () => {
    console.log(titleId.value);
    ElMessageBox.confirm(
        '确定上传该视频?',
        '提示',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {

            //判断视频介绍是否为空
            if (textarea.value === '') {
                ElMessage({
                    type: 'error',
                    message: '视频介绍为空',
                })
                return;
            }
            console.log(namea.value);
            //判断视频为空
            if (namea.value === '') {
                ElMessage({
                    type: 'error',
                    message: '视频为空',
                })
                return;
            }
            //存储api需要信息
            const ade = {
                "titleId": titleId.value,
                "wxVideo": namea.value,
                "details": textarea.value
            }
            console.log(ade);
            //给后端发请求
            instance.post('api/wxVideo', ade)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '视频上传成功',
                    })
                    router.push('/operate')
                }).catch(() => {
                    ElMessage({
                        type: 'error',
                        message: '视频上传失败',
                    })
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消上传',
            })
        })
}

</script>
<style>
.addvideo {
    width: 100vw;
    height: 80vh;
}

.addvideo span {
    font-size: 1rem;
}

.addvideo p {
    padding-top: 5vh;
    display: flex;
    margin-left: 2vw;
}

.addvideo .bus {
    /* padding-left: 1vw; */
    margin-left: 1vw;
}

.addvideo .name {
    width: 9%;
}

.addvideo .el-textarea {
    width: 160% !important;
}

.addvideo .el-select {
    width: 20vw;
}
</style>