<!-- 宣传视频 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="main_topnav">
                        <div class="topnav_input">
                            <!-- <el-input v-model="input1" class="w-50 m-2" size="large" placeholder="搜索视频"
                                :prefix-icon="Search" />
                            <el-button class="input_btn" type="primary">搜索</el-button> -->
                        </div>
                        <el-button class="addStore" type="primary" :icon="Edit" @click="goToAddsp">新增视频</el-button>
                        <!-- <el-button type="primary" class="addStore">
                            导出视频<el-icon class="el-icon--right">
                                <Upload />
                            </el-icon>
                        </el-button> -->
                    </div>
                    <div class="forms">
                        <el-table :data="videos" v-loading="loading" element-loading-text="加载中，请稍后..." height="70vh"
                            border style="width: 100%">
                            <el-table-column prop="createVideoTime" label="上传日期" width="180">
                            </el-table-column>
                            <el-table-column prop="" label="视频" width="320">
                                <template #default="scope">
                                    <video :src="image[scope.$index]" controls></video>
                                </template>
                            </el-table-column>
                            <el-table-column prop="details" label="视频简介" width="320">
                            </el-table-column>
                            <el-table-column prop="" label="操作">
                                <template #default="scope">
                                    <el-button type="primary" class="edit"
                                        @click="goToEdit(scope.row.id, scope.row.details, scope.row.wxVideo, scope.row.titleId)">编辑</el-button>
                                    <el-button type="danger" @click="deleteVideo(scope.row.id)">删除</el-button>
                                    <!-- <el-button type="danger" >删除</el-button> -->
                                </template>
                            </el-table-column>
                        </el-table>

                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { Edit, Upload, Delete, Plus } from '@element-plus/icons-vue'
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import instance from '../network/storester.js'

const loading = ref(true)
const images = ref([])
const image = ref([])
const router = useRouter()
const video = ref(null)
const videos = ref([])
//获取到展示视频添加到表中
instance.get('/api/wxVideo/selectAll')
    .then(response => {
        console.log(response.data.data);
        video.value = response.data.data
        videos.value = toRaw(video.value)
        console.log(videos.value);
        images.value.splice(0, images.value.length);
        for (let i = 0; i < videos.value.length; i++) {
            images.value.splice(i, images.value.length)
            images.value.push(videos.value[i].wxVideo)
        }
        loading.value = false
        console.log(images.value);
        fetchData();
    })
    .catch(error => {
        console.error(error);
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('./')
    });
const goToAddsp = () => {
    router.push('./addsp')
}
//点击跳转，路由传参到editvideo页面
const goToEdit = (id, details, wxVideo, titleId) => {
    console.log(id);
    console.log(details);
    router.push({
        path: './editvideo',
        query: {
            id: id,
            details: details,
            wxVideo: wxVideo,
            titleId: titleId
        }
    })
}
//删除视频
const deleteVideo = (id) => {
    console.log(id)
    ElMessageBox.confirm(
        '是否删除改视频?',
        'Warning',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            //传入id给api
            instance.delete('/api/wxVideo/' + id)
                .then(response => {
                    ElMessage({
                        message: '删除成功',
                        type: 'success',
                    })
                    instance.get('/api/wxVideo/selectAll')
                        .then(response => {
                            console.log(response.data.data);
                            video.value = response.data.data
                            videos.value = toRaw(video.value)
                            console.log(videos.value);
                            images.value.splice(0, images.value.length);
                            for (let i = 0; i < videos.value.length; i++) {
                                images.value.splice(i, images.value.length)
                                images.value.push(videos.value[i].wxVideo)
                            }
                            loading.value = false
                            console.log(images.value);
                            fetchData();
                        })
                        .catch(error => {
                            console.error(error);
                            ElMessage({
                                message: '请重新登录',
                                type: 'error',
                            })
                            router.push('./')
                        });
                    // window.location.reload()
                })
                .catch(error => {
                    console.error(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消删除',
            })
        })

}
async function fetchData() {

    console.log(image.value);
    image.value.splice(0, image.value.length);
    // image.value = []
    for (let j = 0; j < images.value.length; j++) {
        try {
            const response = await instance.get(`/api/common/downloadVideo?name=${images.value[j]}`);
            console.log('333');
            image.value.splice(j, image.value.length);
            image.value.push(response.request.responseURL);
        } catch (error) {
            console.log(error);
        }
    }
    console.log(image.value);
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}

.main_topnav {
    width: 80%;
    height: 7vh;
    position: fixed;
    top: 11vh;
    border: 3px solid #f3f5e9;
    box-shadow: 0px 0px 10px rgba(17, 16, 16, 0.5);
    display: flex;
    align-items: center;
}

.topnav_input {
    width: 30%;
    margin-left: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_btn {
    margin-left: 10px;
}

.addStore {
    margin-left: 30px;
}

.forms {
    display: flex;
    margin-left: 5vw;
}

.forms video {
    width: 25vw;
    height: 25vh;
}
</style>