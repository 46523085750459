<!-- 查询所有提现记录 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="withdrawal">
                        <div class="withdrawalhead">
                            <el-select class="withdrawalSearch" v-model="userinput" placeholder="请选择评论">
                                <el-option label="待处理" value="1" />
                                <el-option label="已完成" value="2" />
                                <el-option label="已驳回" value="3" />
                            </el-select>
                            <el-button type="primary" @click="alluserQuery" class="withdrawalSearchBtn">查询</el-button>
                        </div>
                        <div class="withdrawalbody">
                            <el-table :data="withdrawalsa" height="70vh" border style="width: 90%" v-loading="loading"
                                element-loading-text="加载中，请稍后...">
                                <el-table-column prop="merchantId" label="门店ID" width="200px" />
                                <el-table-column prop="amount" label="提现金额" width="200px" />
                                <el-table-column prop="amountHand" label="实际金额" width="200px">
                                </el-table-column>
                                <el-table-column prop="withdrawalMethod" label="提现方式" width="200px">
                                </el-table-column>
                                <el-table-column prop="status" label="提现状态" width="200px">
                                    <template #default="scope">
                                        <el-tag v-if="scope.row.status === 'pending'" type="info">待处理</el-tag>
                                        <el-tag v-else-if="scope.row.status === 'completed'" type="success">已完成</el-tag>
                                        <el-tag v-else-if="scope.row.status === 'failed'" type="error">已驳回</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="recipientName" label="提现人" width="200px">
                                </el-table-column>
                                <el-table-column prop="recipientAccountNumber" label="提现账号" width="200px">
                                    <template #default="scope">
                                        <span v-if="scope.row.withdrawalMethod == '银行卡'">{{ scope.row.
                                recipientAccountNumber }}</span>
                                        <span
                                            v-else-if="scope.row.withdrawalMethod == '支付宝' || scope.row.withdrawalMethod == '微信'">
                                          
                                            <div class="demo-image__preview">
                                                <el-image style="width: 100px; height: 100px" :src="`https://www.199shq.com/api/common/download?name=${scope.row.paymentCode}`"
                                                    :zoom-rate="1.2" :max-scale="7" :min-scale="0.2"
                                                     :initial-index="4" fit="cover" />
                                            </div>
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="feeType" label="手续费" width="200px">
                                    <template #default="scope">
                                        <span v-if="scope.row.feeType == 'fixed'">固定手续费</span>
                                        <span v-else>feeType</span>
                                    </template>
                                </el-table-column>
                                <el-table-column v-if="userinput === '3'" prop="failedReason" label="驳回原因"
                                    width="200px">
                                </el-table-column>
                                <el-table-column prop="bankAccount" label="联系电话" width="200px">
                                </el-table-column>
                                <el-table-column prop="updateTime" label="提现时间" width="200px">
                                </el-table-column>
                                <el-table-column prop="remarks" label="提现备注" width="200px">
                                </el-table-column>
                                <el-table-column prop="" fixed="right" label="操作" width="200px">
                                    <template #default="{ row }">
                                        <el-button size="small" type="primary" v-if="row.status === 'pending'"
                                            @click="handleEdit(row.merchantId, row.amount, row.id)">通过</el-button>
                                        <el-button size="small" type="danger" v-if="row.status === 'pending'"
                                            @click="handleDelete(row.remarks, row.id)">驳回</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="withdrawalfoot">
                            <div class="demo-pagination-blocks">
                                <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                    @size-change="handleSizeChangese" @current-change="handleCurrentChangese"
                                    :current-page="currentPage4" :page-sizes="[10, 20, 30, 40]" :page-size="10"
                                    layout="total, sizes, prev, pager, next, jumper" :total="tatol">
                                </el-pagination>
                                <!-- <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4"
                                    :page-sizes="[10, 20, 50, 100]" :small="small" :disabled="disabled"
                                    :background="background" layout="total, sizes, prev, pager, next, jumper"
                                    :total="total" @size-change="handleSizeChangese"
                                    @current-change="handleCurrentChangese" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw, reactive } from 'vue'
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const withdrawal = ref([])
const withdrawalsa = ref(null)
const userinput = ref('1')
const loading = ref(true)
const tatol = ref(0);
const router = useRouter();
const page = reactive({ pageNum: 1, pageSize: 10 });


instance.get(`/api/withdrawal/records/getWithdrawalRecordsAccomplishList?pageNum=${page.pageNum}&pageSize=${page.pageSize}&status=pending`)
    .then(response => {
        console.log(response);
        withdrawal.value = response.data.data.records
        withdrawalsa.value = toRaw(withdrawal.value)
        tatol.value = response.data.data.totalCount
        loading.value = false
    }).catch(error => {
        console.log(error);
    })

const alluserQuery = () => {
    loading.value = true
    console.log(userinput.value);
    siftse();
}

const handleSizeChangese = (val) => {
    page.pageSize = val;
    console.log(val);
    siftse();
};
//当前页改变时触发
const handleCurrentChangese = (val) => {
    page.pageNum = val;
    console.log(val);
    siftse();
};
const siftse = () => {
    console.log(page.pageNum);
    console.log(page.pageSize);
    loading.value = true
    if (userinput.value == 1) {
        instance.get(`/api/withdrawal/records/getWithdrawalRecordsAccomplishList?pageNum=${page.pageNum}&pageSize=${page.pageSize}&status=pending`)
            .then(response => {
                console.log('待处理');
                withdrawal.value = response.data.data.records
                withdrawalsa.value = toRaw(withdrawal.value)
                tatol.value = response.data.data.totalCount
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
    } else if (userinput.value == 2) {
        instance.get(`/api/withdrawal/records/getWithdrawalRecordsAccomplishList?pageNum=${page.pageNum}&pageSize=${page.pageSize}&status=completed`)
            .then(response => {
                console.log('已完成');
                withdrawal.value = response.data.data.records
                withdrawalsa.value = toRaw(withdrawal.value)
                tatol.value = response.data.data.totalCount
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
    } else {
        instance.get(`/api/withdrawal/records/getWithdrawalRecordsAccomplishList?pageNum=${page.pageNum}&pageSize=${page.pageSize}&status=failed`)
            .then(response => {
                console.log('失败');
                withdrawal.value = response.data.data.records
                withdrawalsa.value = toRaw(withdrawal.value)
                console.log(response);
                tatol.value = response.data.data.totalCount
                loading.value = false
            }).catch(error => {
                console.log(error);
            })
    }

}
const handleEdit = (merchantId, amount, id) => {
    ElMessageBox.confirm(
        '确认要通过申请嘛?',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    )
        .then(() => {
            instance.put(`/api/withdrawal/records/updateCompleted?amount=${amount}&merchantId=${merchantId}&withdrawalId=${id}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: '已通过',
                    })
                    siftse()
                }).catch(error => {
                    console.log(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '取消成功',
            })
        })
}
const handleDelete = (remarks, id) => {
    ElMessageBox.prompt('输入驳回原因', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
    })
        .then(({ value }) => {
            instance.put(`/api/withdrawal/records/updateFailed?failedReason=${value}&remarks=${remarks}&withdrawalId=${id}`)
                .then(response => {
                    console.log(response);
                    ElMessage({
                        type: 'success',
                        message: `驳回成功`,
                    })
                    siftse()
                }).catch((error) => {
                    console.log(error);
                })
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消',
            })
        })
}

</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}




.withdrawal {
    /* margin-left: -4vw; */
    margin-top: -20vh;
    width: 100vw;
    height: 100vh;
}

.withdrawalbody {
    margin-top: 3vh;
    width: 100vw;
    /* height: 40vh; */
}

.withdrawalfoot {
    width: 100vw;
    height: 10vh;
    /* background-color: red; */
}

.withdrawalhead {
    margin-top: 3vh;
}

.withdrawalSearch {
    /* top: 3vh; */
    width: 35vh;
    left: 1vw;
}

.withdrawalSearchBtn {
    /* margin-top: 5vh; */
    width: 5vw;
    height: 5vh;
    border: none;
    border-radius: 5px;
    margin-left: 1vw;
}

.withdrawalfoot .demo-pagination-blocks {
    position: fixed;
    width: 100vw;
    /* top: 90vh; */
    height: 10vh;
    left: 25%;
    /* background-color: red; */
}

.withdrawal .el-table .cell {
    width: 200px !important;
}
</style>