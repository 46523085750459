<template>
  <nav>
    <router-link to="/"></router-link> 
    <!-- <router-link to="/leftnav">leftnav</router-link> |
    <router-link to="/about">About</router-link> -->
  </nav>
  <router-view/>
</template>

<style>
  
</style>
