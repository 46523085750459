export const options = [
    {
        label: '门店ID',
        prop: 'id',
        width: 50
    },
    {
        label: '门店名字',
        prop: 'name',
        width:100
    },
    {
        label: '门店首页图片',
        prop: 'indexImgUrl',
        width: 100
    },
    {
        label: '门店背景图片',
        prop: 'storeBgimgUrl',
        width: 100
    },
    {
        label: '门店头像图片',
        prop: 'storeTximgUrl',
        width: 100
    },
    {
        label: '门店地址',
        prop: 'address',
        width: 100
    },
    {
        label: '操作',
        prop: 'action',
        width: 400
    },
]