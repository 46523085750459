<!-- 修改密码 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-main class="main">
                <div class="main_item">
                    <div class="modify">
                    <div class="passwoed">
                        <el-form :model="form" label-width="100px">
                            <el-form-item label="原密码">
                                <el-input v-model="oldpassword" type="password" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="新密码">
                                <el-input v-model="newpassword" type="password" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="再次新密码">
                                <el-input v-model="againpassword" type="password" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm">提交</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import instance from '../network/storester.js';
import { useRouter } from 'vue-router'

const router = useRouter()
const oldpassword = ref('');
const againpassword = ref('');
const newpassword = ref('');

//点击提交
const submitForm = () => {
    //判断原密码是否为空
    if (oldpassword.value === '') {
        ElMessage.error('原密码不能为空');
        return;
    }
    //判断新密码是否为空
    if (newpassword.value === '') {
        ElMessage.error('新密码不能为空');
        return;
    }
    //判断重新输入的新密码是否为空
    if (againpassword.value === '') {
        ElMessage.error('重新输入新密码不能为空');
        return;
    }
    //判断新密码是否跟再次新密码相等
    if (againpassword.value !== newpassword.value) {
        ElMessage.error('新密码跟再次新密码不相等');
        return;
    }
    //判断密码长度
    if (newpassword.value.length < 8) {
        ElMessage.error('密码长度不能小于8');
        return;
    }
if (newpassword.value.length > 15) {
        ElMessage.error('密码长度不能大于15');
        return;
    
}
    //保存在params里
    const params = {
        "oldPassword": oldpassword.value,
        "newPassword": newpassword.value,
        "againPassword": againpassword.value
    }
    instance.put('/api/domain/updatePassword', params)
        .then(response => {
            ElMessage({
                    showClose: true,
                    message: '密码修改成功',
                    type: 'success',
                })
                console.log(response);
        }).catch(error =>{
            ElMessage({
                //token失效跳转登录页面
                    message: '请重新登录',
                    type: 'error',
                })
                console.log(error);
                router.push('./')
        })
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}

.container {
    position: fixed;
    top: 0;
    left: 15vw;
}

.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}

.main {
    position: fixed;
    top: 10vh;
    left: 15vw;
    width: 85vw;
    height: 90vh;
}
.modify{
    position: fixed;
    top: 0 !important;
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/modify.jpg');
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
}
.passwoed{
    top: 20vh !important;
    position: fixed;
    width: 20vw;
    height: 40vh;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding:0 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.passwoed .el-form{
    left: -1vw !important;
    position: fixed;
    top: 5vh !important;
}
</style>