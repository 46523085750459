<!-- 编辑门店 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <el-card class="card">
                <el-row :gutter="100">
                    <el-col :span="7">
                        编辑门店
                    </el-col>
                </el-row>
                <el-row :gutter="100">
                    <el-col :span="7">
                        <!-- ref绑定到上面的属性,用于校验验证表单是否正确输入,model绑定的是整个表单数据,rules绑定的是最外层的总体的那个规则对象 -->
                        <el-from ref="baseForm" :model="loginForm" :rules="loginrules">
                            <!--label为显示的内容,prop为规则对象内的一个属性,v-model与表单数据双向绑定-->
                            <el-form-item label="门店名称" prop="storename">
                                <el-input v-model="name" />
                            </el-form-item>
                            <el-form-item label="门店地址" prop="storename">
                                <el-input v-model="loginForm.storaddress" />
                            </el-form-item>
                            <el-form-item label="门店经度" prop="longitude">
                                <el-input v-model="longitude" />
                            </el-form-item>
                            <el-form-item label="门店纬度" prop="latitude">
                                <el-input v-model="latitude" />
                            </el-form-item>
                        </el-from>

                        <!-- 上传一张图片用来作为商店首页图片 -->
                        <p>
                            请先择您要修改的首页图片：
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_1" :show-file-list="false"
                            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_1" :src="imageUrl_1" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />
                            </el-icon>
                        </el-upload>

                        <!-- 上传一张图片用来作为门店头像图片 -->
                        <p>
                            请先择您要修改的门店背景：(建议上传背景图片比例为5：1)
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_2" :show-file-list="false"
                            :on-success="handleAvatarSuccess_1" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_2" :src="imageUrl_2" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />

                            </el-icon>
                        </el-upload>

                        <!-- 上传一张图片用来作为门店背景 -->
                        <p>
                            请先择您要修改的门店头像图片：
                        </p>
                        <el-upload class="avatar-uploader" action="" :http-request="upload_3" :show-file-list="false"
                            :on-success="handleAvatarSuccess_2" :before-upload="beforeAvatarUpload">
                            <img v-if="imageUrl_3" :src="imageUrl_3" class="avatar">
                            <el-icon v-else class="avatar-uploader-icon">
                                <Plus />

                            </el-icon>
                        </el-upload>
                        <p class="buttonse">
                            <el-button type="primary" @click="submit">提交</el-button>
                            <el-button type="danger" @click="cancels">取消</el-button>
                        </p>
                    </el-col>
                </el-row>
            </el-card>
        </el-container>

    </el-container>
</template>

<script setup>
import Menu from '../navbar/Menu.vue'
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { reactive, ref } from 'vue'
import { FromInstance, FormRules } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { useRouter, useRoute } from 'vue-router'
import { watch } from 'vue';
import instance from '../network/storester.js'

const router = useRouter();
const route = useRoute();
const storeId = route.query.msg;
console.log(storeId);
console.log(route.query);
const loginForm = ref({
    storename: route.query.name,
    storaddress: route.query.address,
});
const data = ref(null);
const list = ref([]);
const longitude = ref('')
longitude.value = route.query.longitude
const latitude = ref('')
latitude.value = route.query.latitude
const getStore = () => {
    instance.get(`/api/common/download?name=${route.query.indeximg}`)
        .then(response => {
            console.log(response);
            data.value = response.data;
            // var stores = toRaw(data.value)
            list.value = data.value;
        })
        .catch(error => {
            console.error(error);
            ElMessage({
                message: '请重新登录',
                type: 'error',
            })
            router.push('./')
            // 可以执行其他错误处理逻辑
        });
};
getStore();



// const loginrules = ref({
//     username: [
//         { required: true, message: "信息不能为空", trigger: "blur" },
//         { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
//     ],
//     name: [
//         { required: true, message: "信息不能为空", trigger: "blur" },
//         { min: 3, max: 5, message: '请输入长度为三到五', trigger: 'blur' },
//     ],
// })
//这个baseForm为获取表单的对象ref值,用于对表单规则是否通过进行校验,baseForm绑定到form标签上
const baseForm = ref(null);
const submitForm = () => {
    //对表单元素进行校验
    baseForm.value.validate((valid) => {
        if (!valid) {
            //校验失败
            console.log("请调整数据后再请求");
            return false;
        }
        // 校验成功
        console.log("123123", 123123);
    });
};

//获取到图片的url地址
//用来储存门店首页图片
const imageUrl_1 = ref('');
instance.get(`/api/common/download?name=${route.query.indeximg}`)
    .then(response => {
        imageUrl_1.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
// console.log(imageUrl);
//图片在本地地址
const imgurl1 = ref('');
const upload_1 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            //本地
            imgurl1.value = response.data.data;
            //请求
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_1.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            ElMessage({
                showClose: true,
                message: '图片上传失败，请联系管理员重启分配资源',
                type: 'error',
            })
        })
}
//用来储存门店头像图片
const imageUrl_2 = ref('');
instance.get(`/api/common/download?name=${route.query.bgurl}`)
    .then(response => {
        imageUrl_2.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
//图片在本地地址
const imgurl2 = ref('');
const upload_2 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl2.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_2.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(response => {
            ElMessage({
                showClose: true,
                message: '图片上传失败，请联系管理员重启分配资源',
                type: 'error',
            })
        })
}
//用来储存门店背景图片
const imageUrl_3 = ref('');
instance.get(`/api/common/download?name=${route.query.txurl}`)
    .then(response => {
        imageUrl_3.value = response.request.responseURL
    }).catch(error => {
        console.log(error);
        ElMessage({
            showClose: true,
            message: '请登录',
            type: 'error',
        })
        router.push('/')
    });
console.log(imageUrl_2);
//图片在本地地址
const imgurl3 = ref('');
const upload_3 = (param) => {
    const formData = new FormData();
    formData.append('file', param.file);
    instance.post('/api//common/upload', formData)
        .then(response => {
            console.log('上传图片成功');
            console.log(response.data.data);
            imgurl3.value = response.data.data;
            instance.get(`/api/common/download?name=${response.data.data}`)
                .then(response => {
                    imageUrl_3.value = response.request.responseURL
                }).catch(error => {
                    console.log(error);
                    ElMessage({
                        showClose: true,
                        message: '请登录',
                        type: 'error',
                    })
                    router.push('/')
                });
        }).catch(error => {
            ElMessage({
                showClose: true,
                message: '图片上传失败，请联系管理员重启分配资源',
                type: 'error',
            })
        })
}
console.log(imageUrl_2.value);
// const beforeAvatarUpload = (file) => {
//     // const isJPG = file.type === 'image/jpeg';
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     const timer = ref(null);
//     timer.value = setTimeout(() => {
//         // 执行需要停止的操作
//         alert('响应时间超过规定时间');
//     }, 1000);
//     clearTimeout(timer.value);
//     if (!isLt2M) {
//         alert('上传头像图片大小不能超过 2MB!');
//     }
//     return isJPG && isLt2M;
// };


const name = ref(route.query.name)
// watch(name, (newVal) => {
//     if (newVal.length > 9) {
//         name.value = '';
//         ElMessage({
//             message: '门店名称不能大于9个字，请简写',
//             type: 'warning',
//         })
//     }
// });
console.log(name);
console.log(imgurl1.value);
console.log(imgurl2.value);
console.log(imgurl3.value);
console.log(loginForm.value.storaddress);


//提交并且将数据写入数据库
const submit = () => {
    console.log(latitude.value);
    console.log(longitude.value);
    ElMessageBox.confirm(
        '是否确认修改该门店',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            if (imgurl1.value == '') {
                imgurl1.value = route.query.indeximg;
            }

            if (imgurl2.value == '') {
                imgurl2.value = route.query.bgurl;
            }

            if (imgurl3.value == '') {
                imgurl3.value = route.query.txurl;
            }
            if (name.value == '') {
                name.value = route.query.name;
            }
            if (latitude.value == '') {
                latitude.value = route.query.latitude;
            }
            if (longitude.value == '') {
                longitude.value = route.query.longitude;
            }
            const stores = {
                "id": storeId,
                "name": name.value,
                "indexImgUrl": imgurl1.value,
                "storeBgimgUrl": imgurl2.value,
                "storeTximgUrl": imgurl3.value,
                "address": loginForm.value.storaddress,
                "longitude": longitude.value,
                "latitude": latitude.value,
            };
            console.log(stores);
            instance.put('/api/stores', stores)
                .then(response => {
                    console.log(response.data);
                    console.log('修改成功');
                    ElMessage({
                        message: '门店修改成功',
                        type: 'success',
                    })
                    router.push('/storemanagement');
                })
                .catch(error => {
                    console.error(error);
                });
        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};
const cancels = () => {
    router.push('/storemanagement');
}
</script>

<style>
.leftnavbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 15vw;
    height: 100vh;
    background-color: #545c64;
}


.container_top {
    width: 85vw;
    height: 10vh;
    background-color: #545c64;
}


.card {
    width: 80vw;
    display: flex;
    height: 80vh;
    flex-direction: column;
    overflow-y: auto;
    font-size: 14px;
    margin-top: 10vh;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.card .avatar {
    width: 10vw !important;
    height: 20vh !important;
    display: block;
}

.card .buttonse {
    width: 20vw;
}
</style>