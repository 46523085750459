<!-- 背景图 -->

<template>
    <el-container class="Wrapper">
        <el-container class="container">
            <div class="atas">
                <div class="sta">
                    <p>编辑轮播图</p>
                    <el-upload v-model:file-list="fileList" action="https://199shq.com/api/announcement/upload"
                        name="file" limit="8" list-type="picture-card" :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove" :on-error="handleError">
                        <el-icon>
                            +
                        </el-icon>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                        <img w-full :src="dialogImageUrl" alt="Preview Image" />
                    </el-dialog>
                    <p><el-button type="primary" @click="add">保存</el-button></p>
                </div>
            </div>
        </el-container>
    </el-container>
</template>

<script setup>
import LeftNav from '../navbar/LeftNav.vue'
import TopNav from '../navbar/TopNav.vue'
import { ref, toRaw } from 'vue';
import { Plus } from 'element-plus';
import instance from '../network/storester.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Edit } from 'vxe-table';
import { useRouter, useRoute } from 'vue-router';

const handleError = () => {
    ElMessage({
        showClose: true,
        message: '图片上传失败，请联系管理员重启分配资源',
        type: 'error',
    })
}

const fileList = ref([])
const data = ref('')
const list = ref([])
const router = useRouter()
instance.get(`/api/carousels`)
    .then(response => {
        console.log(response.data.data[0].img1);
        console.log(list.value);
        console.log(response.data.data[0].img8);
        if (response.data.data[0].img8 === null || response.data.data[0].img8 === '' || response.data.data[0].img8 === undefined) {

        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
                {
                    name: 4,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img4}`
                },
                {
                    name: 5,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img5}`
                },
                {
                    name: 6,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img6}`
                },
                {
                    name: 7,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img7}`
                },
                {
                    name: 8,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img8}`
                }
            ]
            return;
        }
        if (response.data.data[0].img7 === null || response.data.data[0].img7 === '' || response.data.data[0].img7 === undefined) {

        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
                {
                    name: 4,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img4}`
                },
                {
                    name: 5,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img5}`
                },
                {
                    name: 6,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img6}`
                },
                {
                    name: 7,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img7}`
                },
            ]
            return;
        }
        if (response.data.data[0].img6 === null || response.data.data[0].img6 === '' || response.data.data[0].img6 === undefined) {
            // imageUrl5.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
                {
                    name: 4,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img4}`
                },
                {
                    name: 5,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img5}`
                },
                {
                    name: 6,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img6}`
                },
            ]
            return;
        }
        if (response.data.data[0].img5 === null || response.data.data[0].img5 === '' || response.data.data[0].img5 === undefined) {
            // imageUrl4.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
                {
                    name: 4,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img4}`
                },
                {
                    name: 5,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img5}`
                },
            ]
            console.log(fileList.value);
            return;
        }
        if (response.data.data[0].img4 === null || response.data.data[0].img4 === '' || response.data.data[0].img4 === undefined) {
            // imageUrl3.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
                {
                    name: 4,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img4}`
                },
            ]
            console.log(fileList.value);
            return;
        }
        if (response.data.data[0].img3 === null || response.data.data[0].img3 === '' || response.data.data[0].img3 === undefined) {
            // imageUrl2.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
                {
                    name: 3,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img3}`
                },
            ]
            return;
        }
        if (response.data.data[0].img2 === null || response.data.data[0].img2 === '' || response.data.data[0].img2 === undefined) {
            // imageUrl1.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                },
                {
                    name: 2,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img2}`
                },
            ]
            return;
        }
        if (response.data.data[0].img1 === null || response.data.data[0].img1 === '' || response.data.data[0].img1 === undefined) {
            // imageUrl.value = '';
        } else {
            fileList.value = [
                {
                    name: 1,
                    url: `https://199shq.com/api/common/download?name=${response.data.data[0].img1}`
                }
            ]
            return;
        }
    })
    .catch(error => {
        //token失效跳转登录页面
        ElMessage({
            message: '请重新登录',
            type: 'error',
        })
        router.push('/');
        console.error(error);
    });
const img1 = ref('')
const img2 = ref('')
const img3 = ref('')
const img4 = ref('')
const img5 = ref('')
const img6 = ref('')
const img7 = ref('')
const img8 = ref('')
const add = () => {
    const regexs = /(https:\/\/199shq.com\/api\/common\/download\?name=([^,]+))/;
    console.log(fileList.value);
    ElMessageBox.confirm(
        '是否确认修改轮播图',
        '提示',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: '提示',
        }
    )
        .then(() => {
            instance.get(`/api/carousels`)
                .then(response => {
                    if (fileList.value[0] === undefined) {
                        if (response.data.data[0].img1 === null || response.data.data[0].img1 === '' || response.data.data[0].img1 === undefined) {
                            img1.value = ""
                        } else {
                            if (fileList.value[0] === undefined) {
                                img1.value = ""
                            } else {
                                img1.value = response.data.data[0].img1
                            }
                        }

                    } else {
                        let matches = fileList.value[0].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img1.value = fileList.value[0].response.data
                            console.log(img1);
                        } else {
                            if (matches[2] === undefined) {
                                img1.value = matches[4]
                                console.log(img1);
                            } else {
                                img1.value = matches[2]
                                console.log(img1);
                            }
                        }
                    }
                    if (fileList.value[1] === undefined) {
                        if (response.data.data[0].img2 === null || response.data.data[0].img2 === '' || response.data.data[0].img2 === undefined) {
                            img2.value = ""
                            console.log('这里');
                        } else {
                            if (fileList.value[1] === undefined) {
                                img2.value = ""
                                console.log(fileList.value[1]);
                            } else {
                                img2.value = response.data.data[0].img2
                                console.log(img2.value);
                            }
                        }
                    } else {
                        let matches = fileList.value[1].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img2.value = fileList.value[1].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img2.value = matches[4]
                            } else {
                                img2.value = matches[2]
                            }
                        }
                    }

                    if (fileList.value[2] === undefined) {
                        if (response.data.data[0].img3 === null || response.data.data[0].img3 === '' || response.data.data[0].img3 === undefined) {
                            img3.value = ""
                        } else {
                            if (fileList.value[2] === undefined) {
                                img3.value = ""
                            } else {
                                img3.value = response.data.data[0].img3
                            }
                        }
                    } else {
                        let matches = fileList.value[2].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img3.value = fileList.value[2].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img3.value = matches[4]
                            } else {
                                img3.value = matches[2]
                            }
                        }
                    }
                    if (fileList.value[3] === undefined) {
                        if (response.data.data[0].img4 === null || response.data.data[0].img4 === '' || response.data.data[0].img4 === undefined) {
                            img4.value = ""
                        } else {
                            if (fileList.value[3] === undefined) {
                                img4.value = ""
                            } else {
                                img4.value = response.data.data[0].img4
                            }
                        }
                    } else {
                        let matches = fileList.value[3].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img4.value = fileList.value[3].response.data

                        } else {
                            if (matches[2] === undefined) {
                                img4.value = matches[4]
                            } else {
                                img4.value = matches[2]
                                console.log(img4);
                            }
                        }
                    }
                    if (fileList.value[4] === undefined) {
                        if (response.data.data[0].img5 === null || response.data.data[0].img5 === '' || response.data.data[0].img5 === undefined) {
                            img5.value = ""
                        } else {
                            if (fileList.value[4] === undefined) {
                                img5.value = ""
                            } else {
                                img5.value = response.data.data[0].img5
                            }
                        }

                    } else {
                        let matches = fileList.value[4].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img5.value = fileList.value[4].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img5.value = matches[4]
                            } else {
                                img5.value = matches[2]
                            }
                        }
                    }
                    if (fileList.value[5] === undefined) {
                        if (response.data.data[0].img6 === null || response.data.data[0].img6 === '' || response.data.data[0].img6 === undefined) {
                            img6.value = ""
                        } else {
                            if (fileList.value[5] === undefined) {
                                img6.value = ""
                            } else {
                                img6.value = response.data.data[0].img6
                            }
                        }

                    } else {
                        let matches = fileList.value[5].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img6.value = fileList.value[5].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img6.value = matches[4]
                            } else {
                                img6.value = matches[2]
                            }
                        }
                    }
                    if (fileList.value[6] === undefined) {
                        if (response.data.data[0].img7 === null || response.data.data[0].img7 === '' || response.data.data[0].img7 === undefined) {
                            img7.value = ""
                        } else {
                            if (fileList.value[6] === undefined) {
                                img7.value = ""
                            } else {
                                img7.value = response.data.data[0].img7
                            }
                        }
                    } else {
                        let matches = fileList.value[6].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img7.value = fileList.value[6].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img7.value = matches[4]
                            } else {
                                img7.value = matches[2]
                            }
                        }
                    }
                    if (fileList.value[7] === undefined) {
                        if (response.data.data[0].img8 === null || response.data.data[0].img8 === '' || response.data.data[0].img8 === undefined) {
                            img8.value = ""
                        } else {
                            if (fileList.value[7] === undefined) {
                                img8.value = ""
                            } else {
                                img8.value = response.data.data[0].img8
                            }
                        }

                    } else {
                        let matches = fileList.value[7].url.match(regexs);
                        console.log(matches);
                        if (matches === null) {
                            img8.value = fileList.value[7].response.data
                        } else {
                            if (matches[2] === undefined) {
                                img8.value = matches[4]
                            } else {
                                img8.value = matches[2]
                            }
                        }
                    }
                    const stores = {
                        "id": 101,
                        "img1": img1.value,
                        "img2": img2.value,
                        "img3": img3.value,
                        "img4": img4.value,
                        "img5": img5.value,
                        "img6": img6.value,
                        "img7": img7.value,
                        "img8": img8.value
                    };
                    console.log(stores);

                    instance.put('/api/carousels', stores)
                        .then(response => {
                            console.log(response.data);
                            console.log('修改成功');
                            ElMessage({
                                message: '轮播图修改成功',
                                type: 'success',
                            })
                        })
                        .catch(error => {
                            console.error(error);
                        });
                })

        })
        .catch(() => {
            ElMessage({
                type: 'info',
                message: '已取消修改',
            })
        })
};
</script>

<style>
.container {
    position: fixed;
    top: 0;
    left: 15vw;
}


.sta {
    overflow: auto;
    /* 设置页面内容溢出时显示滚动条 */
    height: 100%;
    width: 50%;
    position: relative;

    /* display: flex; */
}

.atas {
    width: 80vw;
    height: 90vh;
    display: flex;
    margin-top: 10vh;
}
</style>